<template>
  <div class="pop-form" v-click-outside="close">
    <p
      class="label text-decoration-underline"
      style="cursor: pointer"
      @click="clear"
      v-if="modelData"
    >
      {{ modelData.firstName }} {{ modelData.lastName }}
    </p>
    <div v-else>
      <button class="btn add" @click="toggle">+</button>
      <transition name="fade">
        <div class="talk-bubble left-top" v-show="open">
          <div class="talktext">
            <form @submit.prevent="onSubmit">
              <div>
                <input
                  type="text"
                  class="text"
                  tabindex="-1"
                  v-model="value"
                  :disabled="loading"
                  placeholder="Customer phone"
                />
                <div class="loader" v-show="loading"></div>
              </div>
              <p class="label text-danger">{{ error }}</p>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    model: {
      type: Object,
      requried: true,
    },
    // eslint-disable-next-line no-unused-vars
    submit: async (value) => {},
  },
  data() {
    return {
      open: false,
      value: null,
      modelData: this.model,
      loading: false,
      error: "",
    };
  },
  methods: {
    clear() {
      this.modelData = null;
    },
    close() {
      this.open = false;
    },
    toggle() {
      this.open = !this.open;
    },
    async onSubmit() {
      this.loading = true;
      await this.submit(this.value);
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.label {
  .data {
    text-decoration: underline;
  }
}
.pop-form {
  padding-left: 8px;
  .add {
    outline: none;
    border: 1px solid #e47672;
    background: none;
    color: #e47672;
    border-radius: 5px;
    padding: 0;
    height: 20px;
    font-size: 12px;
    width: 20px;
    position: absolute;
  }

  .primary {
    width: 50px;
  }
}

/* CSS talk bubble */
.talk-bubble {
  margin: 2px 15px 10px -20px;
  display: inline-block;
  position: fixed;
  z-index: 999;
  min-width: 250px;
  right: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  height: auto;
  background-color: white;
  border: 1px solid #e47672;
}

/* talk bubble contents */
.talktext {
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
.loader {
  margin: 10px auto;
  font-size: 10px;
  position: absolute;
  top: 15px;
  right: 25px;
  text-indent: -9999em;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-left: 2px solid #e47672;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
