<template>
  <div class="items">
    <grid-view
      :endpoint="'ims/items'"
      :title="'Inventory List'"
      :headers="[
        'id',
        'name',
        'category',
        'rop',
        'qty',
        'unit',
        'invoiceUnit',
        'supplier',
      ]"
      :actions="['Create', 'Update', 'Delete']"
      @update-model-changed="updateModelChanged"
      :form="form"
    >
      <template v-slot:row="{ row }">
        <td>{{ row.id }}</td>
        <td>{{ row.name }}</td>
        <td>{{ row.category ?? "N/A" }}</td>
        <td>{{ row.rop }}</td>
        <td>{{ row.qty }}</td>
        <td>{{ row.unit }}</td>
        <td>{{ row.invoiceUnit }}</td>
        <td>{{ row.supplier }}</td>
      </template>
      <template style="min-width: 50vw !imporant" v-slot:create>
        <add-item-form :form="form" />
      </template>
      <template v-slot:update>
        <update-item-form :form="form" />
      </template>
    </grid-view>
  </div>
</template>
<script>
import GridView from "@/components/Grid/Grid.vue";
import AddItemForm from "./Forms/AddItemForm.vue";
import UpdateItemForm from "./Forms/UpdateItemForm.vue";
export default {
  components: { GridView, AddItemForm, UpdateItemForm },
  data() {
    return {
      form: {
        vendor: null,
        category: null,
        qty: null,
        name: null,
        rop: null,
        unit: null,
        invoiceUnit: null,
        conversion: null,
        linkedItems: null,
      },
    };
  },
  computed: {},
  methods: {
    updateModelChanged(model) {
      this.form = model;
    },
  },
  mounted: function () {},
  created: function () {
    //this.fetch()
  },
};
</script>
<style lang="scss" scoped>
.items {
  overflow: hidden;
  height: 86vh !important;
  .ps {
    height: 68vh;
  }
}

.tags {
  .tag {
    background: rgb(214, 93, 23);
    border-radius: 5px;
    padding: 2px 8px;
  }
}
</style>
