<template>
  <div class="d-form" v-if="data">
    <form @submit.prevent="submit">
      <div v-for="(input, index) in data" :key="index">
        <p class="label">{{ input.key }}</p>
        <input
          class="text"
          :type="getInputType(input.type)"
          :placeholder="input.key"
          v-model="input.value"
        />
      </div>
    </form>
  </div>
</template>
<script>
export default {
  props: {
    submit: {
      type: Function,
      default: () => {
        alert("submitting");
      },
    },
    keys: {
      type: Array,
    },
    schema: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    getInputType(type) {
      if (type === "string") {
        return "text";
      }
      if (type === "number") {
        return "number";
      }
      if (type === "boolean") {
        return "checkbox";
      }
    },
  },
  mounted: function () {
    this.keys.forEach((element) => {
      if (this.schema[element]) {
        this.data.push({
          key: element,
          type: typeof this.schema[element],
          value: null,
        });
      }
    });
  },
};
</script>
<style lang="scss" scoped></style>
