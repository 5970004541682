import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";

import AdminLayout from "@/layouts/AdminLayout.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import BisLayout from "@/layouts/BisLayout.vue";
import CrmLayout from "@/layouts/CrmLayout.vue";
import DelLayout from "@/layouts/DelLayout.vue";
import ImsLayout from "@/layouts/ImsLayout.vue";

//ERRORS
// import NotFound from "@/views/Errors/NotFound.vue"

//AUTH
import Login from "@/views/AUTH/Login.vue";
import Forgot from "@/views/AUTH/Forgot.vue";
import Account from "@/views/AUTH/Account/Account.vue";

//IMS
import Items from "@/views/IMS/Items.vue";
import Receiving from "@/views/IMS/Receiving.vue";
import Requests from "@/views/IMS/Requests.vue";
import Vendors from "@/views/IMS/Vendors.vue";

//CRM
import CustomersHome from "@/views/CRM/Home";

//CRM
import DeliveryHome from "@/views/DEL/Home";
//USERS
// import UserHome from "@/views/USERS/Index.vue"

//ADMIN
import AdminHome from "@/views/ADMIN/Dashboard.vue";

// const BisLayout = () =>
//   import(
//     /*webpackChunkName: "bis", webpackPrefetch: true */ "@/layouts/BisLayout.vue"
//   );

const BillingHome = () =>
  import(
    /*webpackChunkName: "bis", webpackPrefetch: true */ "@/views/BIS/Home.vue"
  );

const BillingMenu = () =>
  import(
    /*webpackChunkName: "bis", webpackPrefetch: true */ "@/views/BIS/Menu.vue"
  );
const BillingHistory = () =>
  import(
    /*webpackChunkName: "bis", webpackPrefetch: true */ "@/views/BIS/History.vue"
  );
const BillingHeld = () =>
  import(
    /*webpackChunkName: "bis", webpackPrefetch: true */ "@/views/BIS/Held.vue"
  );

const BillingSettings = () =>
  import(
    /*webpackChunkName: "bis", webpackPrefetch: true */ "@/views/BIS/Settings.vue"
  );

const Kitchen = () =>
  import(
    /*webpackChunkName: "bis", webpackPrefetch: true */ "@/views/BIS/Views/Kitchen.vue"
  );

// //BILLING SYSTEM
// import BillingHome from "@/views/BIS/Home.vue"
// import BillingMenu from "@/views/BIS/Menu.vue"
// import BillingHistory from "@/views/BIS/History.vue"
// import BillingHeld from "@/views/BIS/Held.vue"
// import BillingSettings from "@/views/BIS/Settings.vue"
// import Kitchen from "@/views/BIS/Views/Kitchen.vue"
const routes = [
  {
    path: "/",
    name: "App",
    component: AppLayout,
    redirect: "/bis",
    meta: { authorize: true },
    children: [
      {
        path: "account",
        name: "Account",
        meta: { authorize: true },
        component: Account,
      },
      {
        path: "/bis",
        name: "BIS",
        redirect: "/bis/billing",
        component: BisLayout,
        children: [
          {
            path: "billing/:type?/:slot?",
            name: "Billing",
            component: BillingHome,
            props: (route) => ({
              type: route.params.type ?? "Table",
              slot: route.params.slot ?? 1,
            }),
          },
          {
            path: "menu",
            name: "Menu",
            component: BillingMenu,
          },
          {
            path: "history",
            name: "History",
            component: BillingHistory,
          },
          {
            path: "held",
            name: "Held Bills",
            component: BillingHeld,
          },
          {
            path: "settings",
            name: "Settings",
            component: BillingSettings,
          },
          {
            path: "kitchen",
            name: "Kitchen",
            component: Kitchen,
          },
        ],
      },
      {
        path: "/crm",
        name: "CRM",
        redirect: "/crm/customers",
        component: CrmLayout,
        children: [
          {
            path: "customers",
            name: "Customers",
            component: CustomersHome,
          },
        ],
      },
      {
        path: "/del",
        name: "Delivery",
        redirect: "/del/delivery",
        component: DelLayout,
        children: [
          {
            path: "delivery",
            name: "Delivery",
            component: DeliveryHome,
          },
        ],
      },
      {
        path: "/ims",
        name: "IMS",
        component: ImsLayout,
        redirect: "/ims/items",
        children: [
          {
            path: "items",
            name: "Items",
            component: Items,
          },
          {
            path: "receiving",
            name: "Receiving",
            component: Receiving,
          },
          {
            path: "requests",
            name: "Requests",
            component: Requests,
          },
          {
            path: "vendors",
            name: "Vendors",
            component: Vendors,
          },
        ],
      },
      {
        path: "/admin",
        name: "Admin Panel",
        meta: { authorize: true, isAdmin: true },
        component: AdminLayout,
        children: [
          {
            path: "",
            name: "Dashboard",
            component: AdminHome,
          },
        ],
      },
    ],
    // eslint-disable-next-line no-unused-vars
    beforeLeave: (to, from) => {
      // reject the navigation
      return false;
    },
  },
  {
    path: "/auth",
    name: "Auth",
    meta: { authorize: false },
    component: AuthLayout,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
      },
      {
        path: "forgot",
        name: "Forgot",
        component: Forgot,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function removeQueryParams(to) {
  if (Object.keys(to.query).length)
    return { path: to.path, query: {}, hash: to.hash };
}

router.beforeEach((to, from, next) => {
  const { authorize, isAdmin } = to.meta;
  const currentUser = store.getters.getUser;

  if (authorize) {
    if (!currentUser) {
      return next({ path: "/auth/login" });
    }

    var permission = to.matched[1]?.name.toLowerCase();
    if (permission === "account") {
      next();
    }

    var authorized = currentUser.data[permission];

    var childPermission =
      to.matched[to.matched.length - 1]?.name.toLocaleLowerCase();

    // console.log(currentUser.data.roles);
    // // if (currentUser.roles === "Admin") {
    // //   next();
    // // }
    let au = null;
    if (typeof authorized === "object") {
      au = authorized?.find((x) => x.toLocaleLowerCase() === childPermission);
    } else {
      if (typeof childPermission === "string") {
        next();
      } else {
        au = authorized?.toLocaleLowerCase() === childPermission;
      }
    }
    if (!au) {
      return next({
        path: "/auth/login",
        query: {
          returnUrl: to.path,
          msg: "Access denied to [" + to.name + "]",
        },
      });
    }
  }
  next();
});
export default router;
