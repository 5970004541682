<template>
  <div class="order-card card drag-el" draggable>
    <div class="d-flex justify-content-between">
      <div>
        <div class="fw-bold bill-id">Bill #{{ order.id }}</div>
        <div>
          {{ moment(order.createdAt).fromNow() }}
        </div>
        <div class="total">
          <span class="fw-bolder">MVR {{ order.totals?.total }}</span>
        </div>
      </div>
      <div class="text-end">
        <div class="customer-info" v-if="order.customer">
          <div @click="viewCustomer" class="text- badge bg-primary">
            {{ order.customer?.name }}
          </div>
          <div>{{ order.customer?.phoneNumber }}</div>
          <span>{{ order.address }}</span>
        </div>

        <div v-else class="no-customer">
          <span>No customer</span>
        </div>

        <button
          class="btn badge bg-success"
          @click="sendBill"
          :disabled="order.customer === null"
          style="width: 70px; padding: 6px"
        >
          Send Bill
        </button>
      </div>
    </div>
    <div class="order-items">
      <div class="order-item" v-for="item in order.items" :key="item.id">
        <div class="d-flex justify-content-between">
          <div>
            <div class="">x{{ item.qty }} {{ item.name }}</div>
          </div>
          <div class="text-end">
            <div class="">MVR {{ item.price }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    order: {
      type: Object,
    },
  },
  data() {
    return {
      moment,
    };
  },
  methods: {
    startDrag(evt, item) {
      alert("drag");
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.id);
    },
    viewCustomer() {
      this.$router.push({
        name: "customer",
        params: {
          id: this.order.customer.id,
        },
      });
    },
    sendBill() {  
      console.log(this.order);
      this.$api.sendBill(this.order).then(() => {});
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>
<style lang="scss" scoped>
.order-card {
  cursor: pointer;
  margin: 10px 5px;
  padding: 10px;
  font-size: 12px;
  border-left: 5px solid rgb(224, 108, 0);

  &:hover {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
      rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  }
}

.status {
  &.pending {
    background-color: gray;
  }
  &.approved {
    background-color: rgb(0, 139, 19);
  }
}

.bill-id {
  &:hover {
    text-decoration: underline;
  }
}

.badge:disabled {
  background: gray !important;
}
</style>
