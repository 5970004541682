<template>
  <div
    class="file-uploader"
    :class="file ? 'selected' : ''"
    @click="uploadFile"
  >
    <img v-if="preview" :src="preview" />
    <img v-else src="/empty_menu_item.png" />

    <input
      ref="fileUploader"
      @change="onFileChanged"
      type="file"
      id="actual-btn"
      hidden
    />
  </div>
</template>
<script>
export default {
  props: {
    image: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      file: null,
      preview: this.image ? this.image : null,
    };
  },
  methods: {
    uploadFile() {
      if (this.file === null) {
        this.$refs.fileUploader.click();
        return;
      }
      if (this.file) {
        this.file = null;
        this.preview = null;
        this.$refs.fileUploader.click();
      }
    },
    onFileChanged(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.file = files[0];
      this.createImage();
    },
    createImage: function createImage() {
      this.preview = URL.createObjectURL(this.file);
    },
    removeImage: function removeImage() {
      this.preview = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.file-uploader {
  cursor: pointer;
  border-radius: 12px;
  height: 100%;
  overflow: hidden;
  border: 1px dashed gray;
  width: 100%;
  img {
    max-height: 170px;
    border-radius: 12px;
    width: 100%;
    background-size: cover;
    background-position: center;
  }
}
</style>
