<template>
  <div class="update-app">
    <div class="jumbotron d-flex align-items-center min-vh-100">
      <div class="container text-center">
        <h5>Update available!</h5>
        <div>
          <button @click="refresh" class="btn primary w-25">Update!</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    refresh: {
      type: Function,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.update-app {
  position: absolute;
  z-index: 99999;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 1);
}
</style>
