<template>
  <div class="notifications">
    <button class="btn btn-bell" @click="open = !open">
      <span class="dropdown" :class="open ? 'closed' : 'open'"></span>
      <img src="@/assets/bell.png" class="bell rounded-circle" height="25" />
      <span v-if="unread.length > 0" class="counter text-center">
        {{ unread.length }}
      </span>
    </button>

    <div v-if="open" class="profile-actions">
      <div
        class="d-flex border notification-header justify-content-between p-2"
      >
        <h6 class="p-2 text-center">Notification Center</h6>
        <span class="label" @click.stop="readAll">Read all</span>
      </div>
      <div class="list">
        <ul class="list-unstyled">
          <li
            v-for="(notification, index) in notifications"
            :key="index"
            @click.stop="readNotification(notification)"
            :class="[notification.type, notification.read ? 'read' : 'unread']"
          >
            <div>
              <div class="d-flex justify-content-between">
                <div class="title">
                  {{ notification.title ?? "Notification" }}
                </div>
                <div class="label">
                  {{ moment(notification.createdAt).fromNow() }}
                </div>
              </div>
              <div class="content">
                {{ notification.message }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      moment,
      open: false,
      notifications: this.$store.state.notifications,
    };
  },
  watch: {
    // notifications: function (a, b) {
    // },
  },
  computed: {
    unread() {
      return this.notifications.filter((x) => x.read === false);
    },
  },
  methods: {
    readAll() {
      this.notifications.forEach((element) => {
        this.readNotification(element);
      });
    },
    readNotification(notification) {
      notification.read = true;
      notification.readAt = new Date();
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.open = false;
      }
    },
  },
  created() {
    document.addEventListener("click", this.handleClickOutside);
    this.moment = moment;
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>
<style lang="scss" scoped>
.notification-header {
  position: sticky;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  backdrop-filter: blur(5px);
}
.list {
  overflow: auto;
}
.notifications {
  color: #818181;
  &.closed {
    background: #615c69;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  &:active {
    background: #615c69;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  .profile-actions {
    position: absolute;
    width: 28%;
    height: 89vh;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 141;
    border-radius: 12px;
    right: 8px;
    background: #ffffff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    top: 70px;

    ul {
      padding: 0;
      li {
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 0 12px 12px 0;
        &.info {
          border-left: 4px solid blue;
        }

        &.warn {
          border-left: 4px solid yellow;
        }

        &.error {
          border-left: 4px solid red;
        }
        .btn {
          color: white;
          width: 100%;
        }
        cursor: pointer;
        &:hover {
          background-color: #e9e9e9;
        }
        &.unread {
          background: rgb(228, 228, 228);
        }
        pre {
          overflow: hidden;
        }
      }
    }
  }
}

.btn {
  position: relative;
  height: 50px;
  width: 50px;
  .bell {
    filter: invert(100%) !important;
    -webkit-filter: invert(100%) !important;
    margin-top: 10px;
  }
}

.counter {
  width: 15px;
  height: 15px;
  color: white;
  font-size: 11px;
  border-radius: 50%;
  background: red;
  position: absolute;
  top: 10px;
  right: 7 px;
}
</style>
