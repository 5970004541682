<template>
  <div class="dashboard">
    <h1>
      Admin panel will be finished after other system modules and website is
      fully completed. Other modules are near completion. <br />
      <br />
      Other specific functional requirments will be added in the future under
      the After sales services.
    </h1>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped></style>
