<template>
  <div class="bis-layout container-fluid">
    <spinner v-if="$store.state.loading.bis" />
    <div v-else class="row">
      <div style="width: 150px">
        <sidebar />
      </div>
      <div class="col-md" style="padding: 0">
        <router-view></router-view>
      </div>
      <popup
        ref="openShift"
        :show="!$store.state.bis.shift"
        title="Open Shift"
        :showFooter="true"
        :canClose="false"
        :width="'50vw'"
        saveText="Create Shift"
        :save="
          async () => {
            await this.$refs.shift.openShift();
          }
        "
      >
        <open-shift
          ref="shift"
          :key="newShift"
          @shift-created="shiftCreated"
        ></open-shift>
      </popup>
    </div>
  </div>
</template>
<script>
import OpenShift from "../components/BillingSystem/OpenShift.vue";
import Sidebar from "@/components/Sidebar.vue";
// eslint-disable-next-line vue/no-unused-components
export default {
  components: {
    Sidebar,
    OpenShift,
  },
  data() {
    return {
      shiftOpen: null,
      loading: true,
      bis: this.$store.state.bis,
    };
  },
  watch: {},
  methods: {
    shiftCreated() {},
  },
  mounted() {},
  created() {},
};
</script>
<style lang="scss">
.bis-layout {
  overflow: hidden !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.btn {
  &.filter {
    border: 1px solid #707070;
    margin-right: 20px;
    border-radius: 8px;
    padding: 2px 10px;
    color: #707070;
    margin-bottom: 10px;
    &.active {
      background: black;
      color: white;
    }
  }
}
</style>
