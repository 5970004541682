<template>
  <div class="edit-customer" v-if="form">

  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  watch: {},
  data() {
    return {
      form: this.form,
    };
  },
  mounted() {
    // this.$api.getCustomerInvoices().then(res => {
    //   this.form = res.data;
    // });
  }
};
</script>
<style lang="scss" scoped>
.edit-customer {
  max-width: 350px;
}
</style>
