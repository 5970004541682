<template>
  <div
    class="sidebar d-none d-md-block content-area"
    :class="$route.name === 'Billing' ? 'active' : 'in-active'"
  >
    <ul class="sidebar-items">
      <li v-for="(item, index) in getLinks" :key="index">
        <button
          @click="$router.push({ name: item, params: null })"
          :class="$route.name === item ? 'active' : ''"
          class="btn"
        >
          {{ item }}
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  computed: {
    getLinks() {
      var permittedLinks =
        this.$store.state.user?.data[
          this.$route.matched[1]?.name.toLowerCase()
        ];
      if (typeof permittedLinks === "string") {
        var array = [];
        array.push(permittedLinks);
        return array;
      }

      return permittedLinks;
    },
  },
};
</script>
<style lang="scss">
.legends {
  list-style: none;
  padding: 0;
  font-size: 12px;
  position: absolute;

  bottom: 0;

  li {
    display: flex;
    align-items: center;
  }
}
.sidebar {
  background: #e0e0e0;
  border-radius: 12px;

  padding: 10px;
  &.active {
    height: 76.5%;
  }
  &.in-active {
    height: 89vh;
  }
}
.sidebar-items {
  list-style: none;
  padding: 0;
}
.sidebar-items li {
  border-radius: 8px;
  margin-bottom: 5px;

  .btn {
    width: 100%;
    text-align: start;

    &.active {
      background: white;
      font-weight: bold;
    }

    &:hover {
      background: white;
    }
  }
}
</style>
