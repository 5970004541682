<template>
  <div class="vendor-form">
    <form v-if="model" @submit.prevent class="form">
      <div v-if="type === 'edit'">
        <p class="label">Vendor Id</p>
        <input
          type="text"
          :disabled="type === 'edit'"
          class="text"
          v-model="dataModel.id"
          placeholder="Enter business name here"
        />
      </div>
      <div class="d-flex" v-else>
        <div style="width: 100%">
          <p class="label">Business Id</p>
          <input
            type="text"
            class="text"
            v-model="dataModel.businessId"
            placeholder="Enter business registration id"
          />
        </div>
        <div class="mx-2" style="width: 100%">
          <p class="label">Business Name</p>
          <input
            type="text"
            class="text"
            v-model="dataModel.name"
            placeholder="Enter business name here"
          />
        </div>
      </div>
      <div>
        <p class="label">TIN Number</p>
        <input
          type="text"
          class="text"
          v-model="dataModel.taxId"
          placeholder="Enter tax identification number"
        />
      </div>
      <div>
        <p class="label">Contact Number</p>
        <input
          type="number"
          class="text"
          v-model="dataModel.contactNumber"
          placeholder="Enter business contact"
        />
      </div>
      <div>
        <p class="label">Email address</p>
        <input
          type="email"
          class="text"
          v-model="dataModel.email"
          placeholder="Enter business email"
        />
      </div>
      <div>
        <p class="label">Address</p>
        <input
          type="text"
          class="text"
          v-model="dataModel.address"
          placeholder="Enter business address here"
        />
        <p class="label" style="font-size: 10px">House, Street, District</p>
      </div>
      <div>
        <p class="label">Remarks</p>
        <textarea
          class="text-area"
          cols="5"
          placeholder="Enter remarks"
          v-model="dataModel.remarks"
          rows="2"
        ></textarea>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    type: {
      type: String,
      default: "create",
    },
    model: {
      type: Object,
    },
  },
  data() {
    return {
      dataModel: this.model,
    };
  },
  methods: {
    clearForm() {
      this.dataModel = null;
    },
  },
  unmounted() {
    this.clearForm();
  },
};
</script>
<style lang="scss" scoped>
.new-vendor {
  width: 500px;
}
.form {
  div {
    padding-top: 10px;
  }
}
</style>
