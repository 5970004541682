<template>
  <div class="content">
    <div class="img"></div>
    <h6>Please enter your email address used to register your account.</h6>
    <input
      type="email"
      v-model="reset.email"
      class="text"
      placeholder="Email address"
    />
    <router-link to="/auth/login">
      <p class="label text-end">Login</p>
    </router-link>

    <button :disabled="reset.loading" class="btn primary mt-4">
      <span v-if="!reset.loading">Reset</span>
      <span
        v-else
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      reset: {
        email: null,
        loading: false,
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.img {
  width: auto;
  background: url("~@/assets/mail.png") no-repeat;
  background-size: contain;
  width: 60.32px;
  height: 48.25px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}
</style>
