<template>
  <div class="outlet-select">
    <div class="centered">
      <div v-if="!loading.get">
        <div class="outlets" v-for="outlet in outlets" :key="outlet.outletId">
          <div @click="onSelect(outlet)" class="outlet">
            <p>{{ outlet.name }}</p>
            <span
              v-show="loading.outlet === outlet.outletId"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        </div>
      </div>
      <span
        v-show="loading.get"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      outlets: [],
      loading: {
        outlet: null,
        get: false,
      },
    };
  },
  methods: {
    async onSelect(outlet) {
      this.loading.outlet = outlet.outletId;
      await this.$store.dispatch("LOAD_DATA");
      this.$store.dispatch("SET_OUTLET", outlet);
      this.loading.outlet = null;
    },
  },
  created: function () {
    this.loading.get = true;
    this.$api.fetchOutlets().then(
      (response) => {
        this.outlets = response.data;
        this.loading.get = false;
      },
      (error) => {
        this.$notify({
          group: "error",
          title: "Couldn't load outlets",
            text: error.message,
          type: "error",
          duration: 2000,
          speed: 1000,
        });
        //this.$emit("app-init") TODO: ENABLE OFFLINE MODE
        this.loading.get = false;
      }
    );
  },
};
</script>
<style lang="scss" scoped>
.outlet-select {
  .centered {
    position: fixed;
    top: 45%;
    left: 50%;
    color: #afafaf !important;
    -webkit-transition: width 1s ease-in-out, padding-left 1s ease-in-out,
      padding-right 1s ease-in-out;
    -moz-transition: width 1s ease-in-out, padding-left 1s ease-in-out,
      padding-right 1s ease-in-out;
    -o-transition: width 1s ease-in-out, padding-left 1s ease-in-out,
      padding-right 1s ease-in-out;
    transition: width 1s ease-in-out, padding-left 1s ease-in-out,
      padding-right 1s ease-in-out;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);

    a {
      color: #afafaf !important;
      text-decoration: none;
    }

    .logo {
      width: auto;
      background: url("~@/assets/logo.png") no-repeat;
      width: 164px;
      height: 72px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem;
    }

    .content {
      background: rgb(255, 255, 255);
      padding: 3rem 2rem;
      border-radius: 20px;
      width: auto;
      height: auto;

      .text {
        margin-bottom: 20px;
        border-color: #afafaf;
      }
    }
  }

  .outlets {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
    padding: 10px;
    width: 85%;
    cursor: pointer;

    .outlet {
      width: 200px;
      height: 200px;
      text-align: center;
      background: white;
      border-radius: 12px;
      line-height: 200px;
      transition: box-shadow 1s;

      .logo {
        width: auto;
        background-size: contain;
        height: 72px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;
      }

      &:hover {
        box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
          0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
        -webkit-transition: background-color 100ms linear;
        -ms-transition: background-color 100ms linear;
        transition: background-color 100ms linear;
      }
    }
  }
}
</style>
