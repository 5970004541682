<template>
  <div class="loading">
    <vue3Lottie
      style="height: 80vh"
      :animationData="require('../assets/sync.json')"
    />
    <p class="text-center position-absolute bottom-0 w-100">Loading...</p>
  </div>
</template>
<script>
export default {};
</script>
<style lang="css" scoped>
.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background: rgba(255, 255, 255, 1);
}
</style>
