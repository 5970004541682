<template>
  <div class="content">
    <h5 class="text-center">Login</h5>
    <input
      type="username"
      v-model="login.username"
      class="text"
      placeholder="Username"
    />
    <input
      v-model="login.password"
      type="password"
      class="text"
      placeholder="Password"
      name="Username"
    />
    <router-link to="/auth/forgot"> </router-link>
    <p class="text-center">{{ $route.query.msg }}</p>
    <button @click="submit" :disabled="login.loading" class="btn primary mt-4">
      <span v-if="!login.loading">Login</span>
      <span
        v-else
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      login: {
        username: null,
        password: null,
        loading: false,
      },
    };
  },
  methods: {
    submit() {
      this.login.loading = true;
      this.$api.login(this.login).then(
        (response) => {
          this.$store.dispatch("LOGIN_SUCCESS", response.data);
          this.$router.push("/");
          this.login.loading = false;
        },
        (error) => {
          this.login.loading = false;
          this.$notify({
            group: "error",
            title: "Authentication failed",
            text: error.message,
            type: "error",
            duration: 2000,
            speed: 1000,
          });
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped></style>
