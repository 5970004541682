<template>
  <div>
    <button
      @click="addRow"
      style="position: fixed; bottom: 30px; z-index: 999"
      class="btn"
    >
      Add row
    </button>
    <div class="item-request" v-if="model" style="height: 70vh; width: 60vw">
      <div v-if="type !== 'view'">
        <div class="d-flex justify-content-between">
          <div>
            <p class="label">Remarks</p>
            <textarea
              cols="30"
              rows="3"
              class="text-area"
              style="width: 25rem"
              placeholder="Enter remarks"
              v-model="dataModel.remarks"
            ></textarea>
            {{ selectedIndex }}
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex">
          <div class="col-md-6">
            <div class="" v-if="type === 'view'">
              <p class="label pt-1">Requested By: {{ dataModel.createdBy }}</p>
              <p class="label pt-1">Requested At: {{ dataModel.createdAt }}</p>
            </div>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <td>Item name</td>
              <td>Qty</td>
              <td>Unit</td>
              <td>Packaging</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in model.items" :key="item.id">
              <td style="width: 25vw" class="start">
                <auto-complete
                  :items="$store.state.ims.inventories.items"
                  v-if="type !== 'view'"
                  @select="selectedItem($event, index)"
                />
                <input
                  v-else
                  type="text"
                  v-model="item.name"
                  :disabled="type === 'view'"
                  class="text"
                  placeholder="Item name"
                />
              </td>
              <td>
                <input
                  type="number"
                  :disabled="type === 'view'"
                  class="text"
                  v-model="item.rQty"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="text"
                  disabled
                  v-model="item.invoiceUnit"
                />
              </td>
              <td class="end">
                <button class="btn" @click="remove(index)">Remove</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import AutoComplete from "@/components/AutoComplete.vue";

export default {
  components: {
    AutoComplete,
  },
  props: {
    model: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      default: "view",
    },
  },
  data() {
    return {
      dataModel: this.model,
      selectedIndex: null,
      initial: null,
    };
  },
  methods: {
    getIndex(index) {
      return Number(index) + 1;
    },
    addRow() {
      this.dataModel?.items.push({
        id: this.dataModel?.items.length,
        inventoryId: null,
        name: null,
        qty: null,
        rQty: null,
        invoiceUnit: null,
        unit: null,
      });
    },
    remove(index) {
      this.dataModel.items.splice(index, 1);
    },
    selectedItem: function (e, index) {
      if (!e.name) {
        return;
      }
      var item = this.dataModel.items[index];
      item.qty = e.qty;
      item.invoiceUnit = e.invoiceUnit;
      item.unit = e.unit;
      item.inventoryId = e.id;
    },
  },
  unmounted: function () {},
  created: function () {
    this.addRow();
  },
};
</script>
<style lang="scss" scoped>
textarea:disabled {
  border-color: rgb(235, 235, 235);
}

.item-request {
}
</style>
