<template>
  <div class="vendors">
    <grid-view
      :endpoint="'ims/vendors'"
      :title="'Vendors'"
      :headers="['id', 'name', 'address', 'contactNumber', 'taxId']"
      @update-model-changed="updateModelChanged"
      :form="form"
    >
      <template style="min-width: 50vw !imporant" v-slot:create>
        <vendor-form :model="form" />
      </template>
      <template v-slot:update="{ updateForm }">
        <vendor-form :model="updateForm" :type="'update'" />
      </template>
      <template v-slot:row="{ row }">
        <td>{{ row.id }}</td>
        <td>{{ row.name }}</td>
        <td>{{ row.address ?? "N/A" }}</td>
        <td>{{ row.contactNumber }}</td>
        <td>{{ row.taxId }}</td>
      </template>
    </grid-view>
  </div>
</template>
<script>
import GridView from "@/components/Grid/Grid.vue";
import VendorForm from "./Forms/VendorForm.vue";
export default {
  components: { GridView, VendorForm },
  data() {
    return {
      form: {
        id: null,
        name: null,
        address: null,
        businessId: null,
        contactNumber: null,
        email: null,
        remarks: null,
        taxId: null,
      },
    };
  },
  computed: {},
  methods: {
    updateModelChanged(model) {
      this.form = model;
    },
  },
  mounted: function () {},
  created: function () {
    //this.fetch()
  },
};
</script>
<style lang="scss" scoped>
.vendors {
  overflow: hidden;
  height: 86vh !important;
  .ps {
    height: 68vh;
  }
}

.tags {
  .tag {
    background: rgb(214, 93, 23);
    border-radius: 5px;
    padding: 2px 8px;
  }
}
</style>
