<template>
  <div class="auth-layout">
    <div class="centered">
      <div class="logo" />
      <router-view />
    </div>
    <p class="fixed-bottom text-center my-2 copyright text-white label">
      Copyright © 2022 Urbist Pvt Ltd. All rights reserved.
    </p>

    <notifications position="bottom left" group="info" class="nt info" />
    <notifications position="top right" group="warning" class="nt warning" />
    <notifications position="top right" group="error" class="nt danger" />
  </div>
</template>

<style lang="scss">
.auth-layout {
  .centered {
    position: fixed;
    top: 45%;
    left: 50%;
    color: #afafaf !important;
    width: 390px;
    -webkit-transition: width 1s ease-in-out, padding-left 1s ease-in-out,
      padding-right 1s ease-in-out;
    -moz-transition: width 1s ease-in-out, padding-left 1s ease-in-out,
      padding-right 1s ease-in-out;
    -o-transition: width 1s ease-in-out, padding-left 1s ease-in-out,
      padding-right 1s ease-in-out;
    transition: width 1s ease-in-out, padding-left 1s ease-in-out,
      padding-right 1s ease-in-out;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);

    a {
      color: #afafaf !important;
      text-decoration: none;
    }

    .logo {
      width: auto;
      background: url("~@/assets/logo.png") no-repeat;
      width: 164px;
      height: 72px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem;
    }

    .content {
      background: rgb(255, 255, 255);
      padding: 3rem 2rem;
      border-radius: 20px;
      width: auto;
      height: auto;

      .text {
        margin-bottom: 20px;
        border-color: #afafaf;
      }
    }
  }
}
</style>
