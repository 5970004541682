<template>
  <transition name="modal-animation">
    <div v-show="shown" class="modal" @click.self="close">
      <transition name="modal-animation-inner">
        <div :style="['width: ' + width]" v-show="shown" class="modal-inner">
          <div
            v-if="title"
            class="d-flex header top-0 justify-content-between p-2"
          >
            <h3 class="px-2">{{ title }}</h3>
            <button v-show="canClose" class="btn close" @click.stop="toggle">
              X
            </button>
          </div>
          <div
            :style="[
              'padding:' + padding,
              showFooter ? 'margin-bottom: 100px' : '',
            ]"
            style="padding-bottom: 0 !important"
          >
            <slot></slot>
          </div>
          <div class="footer" v-if="showFooter">
            <div class="d-flex justify-content-between">
              <div class="left">
                <button
                  class="btn delete"
                  @click="voidConfirm"
                  v-if="deleteText"
                >
                  <span v-if="!loading.void"> {{ deleteText }}</span>
                  <span
                    v-else
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              </div>
              <div class="d-flex right">
                <div class="totals">
                  <slot name="totals"></slot>
                </div>
                <button
                  class="btn mx-2 cancel"
                  @click="cancel"
                  v-if="cancelText"
                >
                  <span v-if="!loading.cancel"> {{ cancelText }}</span>
                  <span
                    v-else
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
                <button
                  class="btn primary confirm"
                  @click="saveConfirm"
                  v-if="saveText"
                  :disabled="loading.save"
                >
                  <span
                    v-show="loading.save"
                    class="spinner-border spinner-border-sm mx-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span> {{ saveText }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    canClose: {
      type: Boolean,
      default: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: "10px",
      required: false,
    },
    width: {
      type: String,
      defualt: "auto",
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    saveText: {
      type: String,
      default: null,
    },
    cancelText: {
      type: String,
      default: null,
    },
    deleteText: {
      type: String,
      default: null,
    },
    save: {
      type: Function,
      default: async () => {},
    },
    void: {
      type: Function,
      default: async () => {},
    },
    cancel: {
      type: Function,
      default: async () => {},
    },
    closeOnAction: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      shown: this.show,
      loading: {
        cancel: false,
        void: false,
        save: false,
      },
    };
  },
  methods: {
    async saveConfirm() {
      this.loading.save = true;
      await this.save();
      this.loading.save = false;

      if (this.closeOnAction) {
        this.toggle();
      }
    },
    async cancelConfirm() {
      this.loading.cancel = true;
      await this.cancel();
      this.loading.cancel = false;

      if (this.closeOnAction) {
        this.toggle();
      }
    },
    async voidConfirm() {
      this.loading.void = true;
      await this.void();
      this.loading.void = false;

      if (this.closeOnAction) {
        this.toggle();
      }
    },
    toggle() {
      this.shown = !this.shown;
    },
    close() {
      if (this.canClose) {
        this.shown = false;
        this.cancel();
      }
    },
  },
  mounted: function () {},
  created: function () {},
  unmounted() {
    this.shown = false;
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  z-index: 99;
  border-bottom: 1px solid rgb(204, 204, 204);
}

.footer {
  position: sticky;
  bottom: 0;
  padding: 10px;
  border-top: 1px solid rgb(184, 184, 184);
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  z-index: 99;
}

.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}

.modal-animation-inner-enter-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}

.modal-animation-inner-leave-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}

.modal-animation-inner-leave-to {
  transform: scale(0.8);
}

.modal {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  &.freeze {
    backdrop-filter: blur(5px);
  }
  &.not-freeze {
    backdrop-filter: blur(0px);
  }

  .modal-inner {
    padding: 10px;
    position: relative;
    border-radius: 20px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background-color: #fff;
    max-height: 95vh;
    max-width: 90vw;
    overflow-y: auto;
    i {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        color: crimson;
      }
    }

    .delete,
    .cancel {
      &:hover {
        background-color: rgb(222, 222, 222);
      }
    }

    .close {
      border: none;
      font-size: 16px;
      background-color: rgb(245, 245, 245);
      color: rgb(0, 0, 0);
      cursor: pointer;
      z-index: 9;
      margin-left: auto;

      &:hover {
        cursor: pointer;
        background-color: rgb(39, 39, 39);
        color: rgb(245, 245, 245);
      }
    }
  }
}
</style>
