<template>
  <div class="del-home content-area mx-2">
    <div class="row">
      <div
        class="col-md column drop-zone"
        @dragover.prevent
        @dragenter.prevent
        @drop="onDrop($event, 0)"
      >
        <div class="fixed">
          <p>Order Received</p>
          <hr />
        </div>
        <draggable
          class="list-group"
          tag="transition-group"
          :component-data="{
            tag: 'ul',
            type: 'transition-group',
            name: !drag ? 'flip-list' : null,
          }"
          @change="change"
          @move="move"
          @start="startDrag"
          v-model="pending"
          v-bind="dragOptions"
          item-key="order"
        >
          <template #item="{ element }">
            <order-card :order="element" />
          </template>
        </draggable>
      </div>
      <div
        class="col-md column drop-zone"
        @dragover.prevent
        @dragenter.prevent
        @drop="onDrop($event, 1)"
      >
        <div class="fixed">
          <p>Order processing</p>
          <hr />
        </div>
        <draggable
          class="list-group"
          tag="transition-group"
          :component-data="{
            tag: 'ul',
            type: 'transition-group',
            name: !drag ? 'flip-list' : null,
          }"
          v-model="processing"
          v-bind="dragOptions"
          @change="change"
          @start="startDrag"
          @move="move"
          item-key="order"
        >
          <template #item="{ element }">
            <order-card :order="element" />
          </template>
        </draggable>
      </div>
      <div
        class="col-md column drop-zone"
        @dragover.prevent
        @dragenter.prevent
        @drop="onDrop($event, 2)"
      >
        <p>Order on the way</p>
        <hr />
        <draggable
          class="list-group"
          tag="transition-group"
          :component-data="{
            tag: 'ul',
            type: 'transition-group',
            name: !drag ? 'flip-list' : null,
          }"
          v-model="onTheWay"
          v-bind="dragOptions"
          @change="change"
          @start="startDrag"
          @move="move"
          item-key="order"
        >
          <template #item="{ element }">
            <order-card :order="element" />
          </template>
        </draggable>
      </div>
      <div
        class="col-md column drop-zone"
        @dragover.prevent
        @dragenter.prevent
        @drop="onDrop($event, 3)"
      >
        <p>Delivered</p>
        <hr />
        <draggable
          class="list-group"
          tag="transition-group"
          :component-data="{
            tag: 'ul',
            type: 'transition-group',
            name: !drag ? 'flip-list' : null,
          }"
          v-model="delivered"
          @change="change"
          @start="startDrag"
          @move="move"
          v-bind="dragOptions"
          item-key="order"
        >
          <template #item="{ element }">
            <order-card :order="element" />
          </template>
        </draggable>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";

import OrderCard from "@/components/DEL/OrderCard.vue";
export default {
  components: { OrderCard, draggable },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    bills() {
      return this.$store.state.bis?.bills.filter(
        (x) => x.payment === "Pending"
      );
    },
    pending() {
      return this.bills.filter(
        (b) => b.type === "Delivery" && b.deliveryStatus === "Pending"
      );
    },
    processing() {
      return this.bills.filter(
        (b) => b.type === "Delivery" && b.deliveryStatus === "Processing"
      );
    },
    onTheWay() {
      return this.bills.filter(
        (b) => b.type === "Delivery" && b.deliveryStatus === "OnTheWay"
      );
    },
    delivered() {
      return this.bills.filter(
        (b) => b.type === "Delivery" && b.deliveryStatus === "Delivered"
      );
    },
  },
  methods: {
    delivertStatus(index) {
      switch (index) {
        case 0:
          return "Pending";
        case 1:
          return "Processing";
        case 2:
          return "OnTheWay";
        case 3:
          return "Delivered";
        default:
          break;
      }
    },
    async onDrop(evt, item) {
      const newStatus = this.delivertStatus(item);

      if (newStatus === this.selected.deliveryStatus) {
        return;
      }

      this.selected.deliveryStatus = newStatus;
      this.$store.commit("UPDATE_DELIVERY_STATUS", this.selected);

      await this.$api.updateBill(this.selected);
    },
    startDrag(evt) {
      this.selected = evt.item._underlying_vm_;
    },
  },
};
</script>
<style lang="scss">
.del-home {
  height: 89vh;
}

.column {
  height: 86vh;
  margin: 0 5px;
  border-right: 1px solid gray;
  overflow-y: scroll;
}

.drop-zone {
  &:-moz-drag-over {
    background-color: #eee;
    margin-bottom: 10px;
    cursor: copy;
  }
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}
</style>
