<template>
  <div class="grid">
    <div class="loading center" v-if="loading.get">
      <span
        v-show="loading.get"
        style="width: 50px; height: 50px"
        class="spinner spinner-border spinner-border-md"
        role="status"
        aria-hidden="true"
      ></span>
      <span>loading</span>
    </div>
    <div class="content" v-else>
      <popup
        ref="create"
        style="min-width: 50vw"
        :showFooter="true"
        saveText="Create"
        :title="'Add'"
        :save="
          async () => {
            await create();
          }
        "
      >
        <slot name="create" />
      </popup>
      <popup
        ref="update"
        style="min-width: 50vw"
        :showFooter="true"
        saveText="Update"
        :title="'Update'"
        :save="
          async () => {
            await update();
          }
        "
      >
        <slot name="update" :updateForm="updateForm" :key="model.id" />
      </popup>
      <popup
        ref="delete"
        style="min-width: 50vw"
        :showFooter="true"
        saveText="Delete"
        :key="selectedIndex"
        :title="'Delete'"
        :save="
          async () => {
            remove();
          }
        "
      >
        <p class="label text-danger">
          Are you sure you want to delete?. This action cannot be reversed!
        </p>
      </popup>
      <div class="header d-flex justify-content-between">
        <p class="title">{{ title }}</p>
        <div class="actions">
          <input
            type="text"
            placeholder="Search"
            v-model="search"
            style="height: 35px; width: 200px"
            class="transparent-input label"
            v-if="items.length > 0"
          />
          <button
            v-if="actions.includes('Create')"
            class="btn"
            @click="createItem"
          >
            Create
          </button>
          <button
            v-if="actions.includes('Delete') && items.length > 0"
            class="btn"
            @click="deleteItem"
          >
            Delete
          </button>
          <button
            v-if="actions.includes('Edit') && items.length > 0"
            :disabled="selectedIndex === null"
            class="btn"
            @click="editItem"
          >
            Edit
          </button>
        </div>
      </div>
      <table class="table" v-if="items.length > 0">
        <thead class="sticky" style="top: ">
          <tr>
            <th scope="col" v-for="(item, index) in headers" :key="index">
              {{ item }}
            </th>

            <th scope="col text-end" v-if="actions['delete']"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in getItems"
            :key="index"
            @click="select(row, index)"
            @dblclick="editItem"
            :class="index === selectedIndex ? 'selected' : ''"
          >
            <slot name="row" :row="row" />

            <td v-if="actions['delete']">
              <div
                class="d-flex"
                @click="deleteItem(index)"
                style="cursor: pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="color: gray; width: 20px; height: 20px"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-else class="center empty">
        <img src="@/assets/empty.png" class="image" alt="" />
        <p>No items found</p>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    title: {
      type: String,
      default: "Title",
    },
    endpoint: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    actions: {
      type: Array,
      default: ["Create", "Edit"],
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    body: {
      type: String,
      default: null,
    },
    searchProp: {
      type: String,
      default: "name",
    },
    allowEdit: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "json",
    },
  },
  data() {
    return {
      moment,
      model: this.form,
      updateForm: null,
      selectedIndex: null,
      items: [],
      search: "",
      selectedIndex: 0,
      selectedItem: null,
      editMode: false,
      loading: {
        get: false,
        delete: false,
        update: false,
      },
    };
  },
  watch: {},
  computed: {
    getItems() {
      if (this.search === "") {
        return this.items;
      }

      return this.items.filter((item) => {
        return String(item[this.searchProp])
          .toLowerCase()
          .includes(this.search?.toLowerCase());
      });
    },
  },
  methods: {
    isValidDate(date) {
      return (
        date &&
        Object.prototype.toString.call(date) === "[object Date]" &&
        !isNaN(date)
      );
    },
    onUpdate() {},
    editItem() {
      if (!this.allowEdit) return;
      this.editMode = true;
      this.model = this.selectedItem;
      this.$emit("update-model-changed", this.model);
      this.updateForm = { ...this.model };
      this.$refs["update"].toggle();
    },
    createItem() {
      this.editMode = false;

      this.$refs["create"].toggle();
    },
    deleteItem() {
      this.editMode = false;
      this.$refs["delete"].toggle();
    },
    select(item, index) {
      this.selectedIndex = index;
      this.selectedItem = { ...item, file: null };
    },
    getValue(item, header) {
      if (item === null) {
        return "N/A";
      }
      if (typeof item === "object") {
        return "N/A";
      }

      if (header === "createdAt" || header === "modifiedAt") {
        return moment(item).format("MMM DD, YYYY hh:mm:ss");
      }

      if (typeof item === "number") {
        return item;
      }

      return item;
    },
    getData(item) {},
    async create() {
      var result = await this.$api.createAsync(
        this.endpoint + "/create",
        this.form,
        this.body
      );

      console.log(result);
      this.$refs["create"].toggle();

      if (!result.succeeded) {
        this.items.unshift(result.data);
      } else {
        this.$notify({
          group: "error",
          title: "Error",
          text: result.message,
          type: "error",
          duration: 2000,
          speed: 1000,
        });
      }
    },
    async update() {
      var result = await this.$api.updateAsync(
        this.endpoint + "/update",
        this.updateForm,
        this.body
      );
      if (result.succeeded) {
        this.$notify({
          group: "error",
          title: "Error",
          text: result.message,
          type: "error",
          duration: 2000,
          speed: 1000,
        });
      }
      {
        this.items[this.selectedIndex] = result.data;
      }
      this.$refs["update"].toggle();
    },
    async remove() {
      var result = await this.$api.deleteAsync(
        this.endpoint + "/delete",
        this.selectedItem?.id
      );

      if (!result.succeeded) {
        this.items[this.selectedIndex] = result.data;
      }
      {
        this.$notify({
          group: "error",
          title: "Error",
          text: result.message,
          type: "error",
          duration: 2000,
          speed: 1000,
        });
      }
      this.$refs["delete"].toggle();

      this.items.splice(this.selectedIndex, 1);
    },
    resetForm() {
      var item = this.items[0];
      var model = {};

      for (var key in item) {
        model[key] = "";
      }

      this.model = model;

      this.$emit("update-model-changed", this.model);
    },
  },
  async mounted() {},
  async created() {
    this.moment = moment;
    this.loading.get = true;

    var response = await this.$api.getAsync(this.endpoint);

    this.loading.get = false;
    this.items = response.data.items;
  },
};
</script>
<style lang="scss" scoped>
thead {
  border-collapse: collapse !important;

  th {
  }
}
.loading {
  height: 100%;
  position: relative;
  text-align: center;
  .spinner {
    display: table;
    margin-top: 20vh;
  }
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  p {
    font-size: 26px;
    text-align: center;
  }
  .image {
    width: 50vw;
  }
}

.table {
  text-align: left;
  position: relative;
  border-collapse: collapse;

  th {
    border-style: hidden !important;
    background: rgb(179, 179, 179);
    position: sticky;
    top: 40px; /* Don't forget this, required for the stickiness */
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    text-transform: uppercase;
  }

  tr {
    background: white;
  }

  .selected {
    background: rgb(228, 228, 228);
  }
}

.grid {
  height: 85vh;
  position: relative;
  overflow: auto;

  .header {
    position: sticky;
    z-index: 2;
    top: 0;
    background: white;
    padding: 0 10px;

    .title {
      font-weight: bold;
      font-size: 18px;
    }
  }
}
</style>
