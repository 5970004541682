<template>
  <div class="account row g-0">
    <div class="col-md-2 content-area mx-2"></div>
    <div class="col-md content-area px-2"></div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss"></style>
