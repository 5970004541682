<template>
  <div class="autocomplete" ref="ac" v-if="items">
    <div>
      <input
        v-model="search"
        @input="onChange"
        type="text"
        class="text"
        :class="isOpen ? 'open' : 'close'"
        @focus="focus"
        :placeholder="placeholder"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter="onEnter"
      />
      <div v-show="!isLoading" @click="onChange" class="arrow"></div>
      <div v-show="isLoading" class="spin">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
      <div v-show="isOpen">
        <ul v-if="display === 'list'" class="autocomplete-results">
          <li class="loading" v-if="isLoading">Loading results...</li>
          <li v-if="search.length > 0 && results.length === 0">
            <button class="btn" v-if="addNew">Add new</button>
            <button class="btn" v-else>Not found</button>
          </li>
          <li v-if="search.length === 0 && results.length === 0">
            Type to search
          </li>
          <li
            v-else
            v-for="(result, i) in results"
            :key="i"
            @click="setResult(result)"
            class="autocomplete-result"
            :class="{ 'is-active': i === arrowCounter }"
          >
            {{ result[this.pattern] }}
          </li>
        </ul>
        <div v-else class="autocomplete-results">
          <table class="table table-striped">
            <tbody>
              <tr v-if="search.length === 0 && results.length === 0">
                <td>Type to search</td>
              </tr>
              <tr v-if="search.length > 0 && results.length === 0">
                <td @click="searchCloud">
                  Search {{ pattern }} '{{ search }}' in cloud
                </td>
              </tr>
              <tr
                class="autocomplete-tr-result"
                v-for="(result, i) in results"
                :key="i"
                @click="setResult(result)"
                :class="{ 'is-active': i === arrowCounter }"
              >
                <td v-for="attribute in attributes" :key="attribute">
                  {{ result[attribute] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "auto-complete",
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    display: {
      type: String,
      default: "list",
    },
    attributes: {
      type: Array,
      default: () => [],
    },
    addNew: {
      type: Boolean,
      default: false,
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false,
    },
    pattern: {
      type: String,
      default: "name",
    },
    selectToInput: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Type something",
    },
    selectedItem: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      isOpen: false,
      results: [],
      search: "",
      isLoading: false,
      arrowCounter: -1,
      selected: null,
      selectedIndex: null,
      cloudSearch: false,
    };
  },
  watch: {
    items: function (value, oldValue) {
      if (value.length !== oldValue.length) {
        this.results = value;
        this.isLoading = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    if (this.selectedItem) {
      var result = this.items.find(
        (x) => x[this.pattern] === this.selectedItem
      );

      this.setResult(result);
    }
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    searchCloud() {
      this.isLoading = true;
      this.$api.getBill(this.search).then(
        (response) => {
          this.setResult(response.data);
          this.isLoading = false;
        },
        (error) => {
          this.$notify({
            group: "error",
            title: "Search failed",
            text: error.response?.data?.message,
            type: "warn",
            duration: 2000,
            speed: 1000,
          });
          this.isLoading = false;
          this.cloudSearch = false;
          this.search = "";
        }
      );
    },
    focus() {
      this.isOpen = true;
    },
    setResult(result) {
      this.selected = result;
      this.$emit("select", result, this.index);
      this.isOpen = false;

      if (this.selectToInput) {
        this.search = result[this.pattern];
      } else {
        this.search = "";
      }
    },
    filterResults() {
      var result = this.items?.filter((item) => {
        return this.pattern
          ? item[this.pattern]
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1
          : item.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });

      this.results = result;
    },
    onChange() {
      this.$emit("input", this.search);
      if (this.isAsync) {
        this.isLoading = true;
      } else {
        this.filterResults();
        this.isOpen = true;
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
        if (!this.selectToInput) {
          this.search = "";
        }
      }
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    async onEnter() {
      if (this.results.length === 0) {
        await this.searchCloud();
      } else {
        this.setResult(this.results[this.arrowCounter]);
      }
      this.arrowCounter = -1;
    },
  },
};
</script>

<style lang="scss">
.autocomplete {
  position: relative;

  .arrow {
    background: url("~@/assets/arrow_down.png") no-repeat;
    height: 10px;
    width: 10px;
    position: absolute;
    right: 10px;
    top: 18px;
    cursor: pointer;
  }

  .spin {
    height: 10px;
    width: 10px;
    position: absolute;
    right: 15px;
    top: 12px;
    cursor: pointer;
  }

  .ps {
    max-height: 500px;
  }
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #c5c5c5;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #818181;
  min-height: 50px;
  width: 100%;
  overflow-y: scroll;
  max-height: 50vh;
  position: absolute;
  background: white;
  right: 0;
  z-index: 1;
  cursor: pointer;

  li {
    padding-left: 10px;
    border-bottom: 1px solid rgb(219, 219, 219);
  }
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  z-index: 1;
  cursor: pointer;

  &.is-active {
    background-color: #4aae9b;
    color: white;
  }
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: rgb(219, 219, 219) !important;
}

.autocomplete-tr-result.is-active,
.autocomplete-tr-result:hover {
  background-color: rgb(219, 219, 219) !important;
}
</style>
