<template>
  <div class="receiving">
    <popup ref="invoice" :title="'Invoice'">
      <ReceivingForm :form="invoice" :type="'view'" v-if="invoice" />
    </popup>
    <grid-view
      :endpoint="'ims/receivings'"
      :title="'Invoices'"
      :headers="[
        'id',
        'invoiceNumber',
        'vendor',
        'subtotal',
        'tax',
        'total',
        'createdAt',
        'createdBy',
      ]"
      @update-model-changed="updateModelChanged"
      :form="form"
      :allowEdit="false"
      searchProp="invoiceNumber"
    >
      <template style="min-width: 50vw !imporant" v-slot:create>
        <ReceivingForm :form="form" />
      </template>
      <template v-slot:row="{ row }">
        <td>{{ row.id }}</td>
        <td>
          <span
            @click.self="viewInvoice(row)"
            style="text-decoration: underline; cursor: pointer; color: blue"
          >
            {{ row.invoiceNumber }}
          </span>
        </td>
        <td>{{ row.vendor?.name ?? "N/A" }}</td>
        <td>{{ row.subtotal }}</td>
        <td>{{ row.tax }}</td>
        <td>{{ row.total }}</td>
        <td>{{ moment(row.createdAt).format("DD MMM YYYY HH:mm") }}</td>
        <td>{{ row.createdBy }}</td>
      </template>

      <template v-slot:update>
        <!-- <input type="text" placeholder="Create name" class="text" /> -->
      </template>
    </grid-view>
  </div>
</template>
<script>
import moment from "moment";
import GridVue from "@/components/Grid/Grid.vue";
import Invoice from "./Modals/Invoice.vue";
import ReceivingForm from "./Forms/ReceivingForm.vue";
export default {
  components: { "grid-view": GridVue, Invoice, ReceivingForm },
  data() {
    return {
      moment,
      form: {
        invoiceNumber: null,
        vendorId: null,
        paymentStatus: null,
        invoiceDate: new Date(),
        vendor: null,
        items: [
          {
            name: null,
            description: null,
            qty: null,
            unit: null,
            unitPrice: null,
            total: null,
            taxInclusive: true,
            inventoryId: null,
            packaging: "N/A",
          },
        ],
        subtotal: 0,
        discount: 0,
        tax: 0,
        total: 0,
      },
      invoice: null,
    };
  },
  methods: {
    viewInvoice(invoice) {
      this.invoice = invoice;
      this.$refs.invoice.toggle();
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>
<style lang="scss"></style>
