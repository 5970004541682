<template>
  <div class="create-item">
    <form @submit.prevent class="form">
      <div class="d-flex">
        <div>
          <p class="label">Supplier</p>
          <auto-complete
            placeholder="Supplier"
            pattern="name"
            :items="$store.state.ims.vendors.items"
            :selectToInput="true"
            :selectedItem="form.vendor?.name"
            @select="selectVendor"
          />
        </div>
        <div style="margin-left: 10px">
          <p class="label">Category</p>
          <auto-complete
            placeholder="Category"
            pattern="name"
            :items="categories"
            :selectToInput="true"
            :selectedItem="form.category"
            @select="selectCategory"
          />
        </div>
      </div>

      <div class="d-flex">
        <div style="width: 100%">
          <p class="label">Item Name</p>
          <input
            type="text"
            class="text"
            v-model="form.name"
            placeholder="Item Name"
          />
        </div>
        <div style="margin-left: 10px; width: 180px">
          <p class="label">Qty on hand (Initial)</p>
          <input
            type="number"
            class="text"
            v-model="form.qty"
            placeholder="Qty on hand"
          />
        </div>
      </div>

      <div class="d-flex">
        <div>
          <p class="label">ROP (ReOrder Point)</p>
          <input
            type="number"
            class="text"
            v-model="form.rop"
            placeholder="ROP"
          />
        </div>
        <div style="margin-left: 10px">
          <p class="label">Inventory Unit</p>
          <input
            type="text"
            class="text"
            v-model="form.unit"
            @change="unitChanged"
            placeholder="Inventory Unit"
          />
        </div>
      </div>
      <div class="d-flex">
        <div>
          <p class="label">Invoice Unit</p>
          <input
            type="text"
            class="text"
            v-model="form.invoiceUnit"
            placeholder="Invoice Unit"
            @change="invoiceUnitChanged"
          />
        </div>
        <div style="margin-left: 10px">
          <p class="label">Conversion</p>
          <input
            type="number"
            class="text"
            v-model="form.conversion"
            placeholder="Conversion"
          />
          <p class="label">{{ conversionText }}</p>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      categories: [
        { name: "Food" },
        { name: "Beverage" },
        { name: "Ingredients" },
        { name: "Others" },
      ],
    };
  },
  methods: {
    selectVendor(vendor) {
      this.form.vendor = vendor;
    },
    selectCategory(cat) {
      this.form.category = cat.name;
    },
  },
  computed: {
    conversionText() {
      if (this.form.unit && this.form.invoiceUnit) {
        return (
          "1 " +
          this.form.invoiceUnit +
          " = " +
          this.form.conversion +
          " " +
          this.form.unit
        );
      }

      return "";
    },
  },
};
</script>
<style lang="scss" scoped></style>
