<template>
  <div class="ims-layout container-fluid">
    <spinner v-if="$store.state.loading.ims" />
    <div v-else class="row">
      <div style="width: 150px">
        <Sidebar />
      </div>
      <div class="col-md" style="padding: 0">
        <div style="margin-right: 10px; height: 100%" class="content-area">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";

export default {
  components: { Sidebar },
  data() {
    return {
      loading: false,
      error: null,
    };
  },
  created: function () {},
};
</script>
<style lang="scss"></style>
