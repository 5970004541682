<template>
  <div class="crm-layout">
    <div class="row" style="margin: 0; padding: 0">
      <div style="width: 200px">
        <Sidebar />
      </div>
      <div class="col-md" style="padding-left: 0 !important">
        <div style="height: 100%; width: 100%; margin: 0" class="content-area">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";

export default {
  components: { Sidebar },
};
</script>
<style lang="scss"></style>
