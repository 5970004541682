import { createApp } from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
// Optional: Import default CSS
import restApi from "./services/restApiService"
import Spinner from "@/components/Spinner.vue"
import Popup from "@/components/Popup.vue"
import Filter from "@/components/Filter.vue"
import AutoComplete from "@/components/AutoComplete.vue"
import DataList from "@/components/DataList.vue"
import Search from "@/components/Search.vue"
import DForm from "@/components/DynamicForm.vue"
import PopForm from "@/components/PopForm.vue"
import "@imengyu/vue3-context-menu/lib/vue3-context-menu.css"
import ContextMenu from "@imengyu/vue3-context-menu"
import Notifications from "@kyvg/vue3-notification"
import Notification from "@/components/Notifications.vue"
import Vue3Lottie from "vue3-lottie"
import "vue3-lottie/dist/style.css"

const app = createApp(App)
app.config.globalProperties.$api = restApi
app.config.globalProperties.$popup = Popup
// app.config.globalProperties.$messaging = firebaseMessaging
const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value()
      }
    }
    document.addEventListener("click", el.clickOutsideEvent)
  },
  unmounted: (el) => {
    document.removeEventListener("click", el.clickOutsideEvent)
  },
}

app.component("auto-complete", AutoComplete)
app.component("spinner", Spinner)
app.component("filter", Filter)
app.component("d-form", DForm)
app.component("d-list", DataList)
app.component("search", Search)
app.component("popup", Popup)
app.component("pop-form", PopForm)
app.component("notifications", Notification)
app.use(store)
app.use(ContextMenu)
app.use(Notifications)
app.use(Vue3Lottie)

app.use(router)
app.directive("click-outside", clickOutside)
app.mount("#app")
