<template>
  <nav
    v-if="$store.getters.isAuthenticated"
    class="navbar navbar-expand-lg navbar-light"
  >
    <popup :title="'Account information'" ref="profileEdit">
      <edit-account-modal></edit-account-modal>
    </popup>
    <popup
      :title="'End shift'"
      :canClose="true"
      key="endShiftModalPopup"
      ref="endShift"
    >
      <end-shift-modal v-if="shiftEnd" key="endShiftModal" />
    </popup>
    <div class="container-fluid">
      <div @click="$router.push('/')">
        <a class="navbar-brand mt-2 mt-lg-0" href="#">
          <img src="@/assets/logo.png" width="120" alt="" class="logo" />
        </a>
      </div>
      <div class="d-none d-md-flex" v-if="isNotAdminPanel">
        <NavbarButton route="bis" title="Billing" v-if="user.data.bis" />
        <NavbarButton route="crm" title="Customers" v-if="user.data.crm" />
        <NavbarButton route="ims" title="Inventory" v-if="user.data.ims" />
        <NavbarButton route="del" title="Delivery" v-if="user.data.del" />
        <!-- <NavbarButton
          route="outlets"
          title="Outlets"
          v-if="user.data.roles === 'Admin'"
        />
        <NavbarButton
          route="reports"
          title="Reports"
          v-if="user.data.roles === 'Admin'"
        /> -->
      </div>

      <div class="d-none d-md-flex">
        <!-- <div class="data"></div> -->
        <div v-if="$store.state.bis.shift">
          <p class="mt-3" :class="shiftCheck ? 'glow-danger' : 'text-white'">
            <span>
              {{
                moment($store.state.bis.shift?.date).format("MMM DD, YYYY")
              }} </span
            ><br />
          </p>
        </div>
        <div v-else>
          <p class="mt-3" :class="shiftCheck ? 'glow-danger' : 'text-white'">
            <span> No Shift</span><br />
          </p>
        </div>
        <notifications />
        <div
          class="d-flex align-items-center user"
          :class="open ? 'closed' : 'open'"
          @click="toggle"
        >
          <div style="line-height: 15px" class="text-end">
            <span class="text-white">
              <span style="font-size: 14px">{{ user.data.dn }}</span> <br />
              <span style="font-size: 12px"> {{ user.data.email }}</span>
            </span>
          </div>
          <button class="btn profile">
            <span class="dropdown" :class="open ? 'closed' : 'open'"></span>
            <img v-if="user.avatar" :src="user.avatar" class="n-avatar" />
            <img v-else src="@/assets/avatar.png" class="n-avatar" />
          </button>
          <div v-if="open" class="profile-actions">
            <ul class="list-unstyled">
              <li>
                <button @click="account" class="btn">Account</button>
              </li>
              <li>
                <button @click="endShift" class="btn">End Shift</button>
              </li>
              <li><button @click="logout" class="btn">Logout</button></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="d-none d-sm-flex d-md-none">
        <button class="btn profile">
          <span class="dropdown" :class="open ? 'closed' : 'open'"></span>
          <img v-if="user.avatar" :src="user.avatar" class="n-avatar" />
          <img v-else src="@/assets/avatar.png" class="n-avatar" />
        </button>
      </div>
    </div>
  </nav>
</template>
<script>
import moment from "moment";
import EditAccountModal from "../views/Modals/EditAccountModal.vue";
import EndShiftModal from "@/views/BIS/Modals/EndShiftModal.vue";
import Notifications from "./Notifications.vue";
import NavbarButton from "@/components/Buttons/NavbarButtons.vue";
export default {
  components: { NavbarButton, EditAccountModal, EndShiftModal, Notifications },
  data() {
    return {
      user: this.$store.state.user,
      open: false,
      shiftEnd: false,
    };
  },
  computed: {
    isNotAdminPanel() {
      var route = this.$route;
      return route.matched[1].name === "Admin Panel" ? false : true;
    },
    shiftCheck() {
      return (
        moment(this.$store.state.bis.shift?.date).format("MMM DD, YYYY") !==
        moment(Date.now()).format("MMM DD, YYYY")
      );
    },
  },
  methods: {
    selectOutlet() {
      this.$store.state.outlet = null;
    },
    toggle() {
      this.open = !this.open;
    },
    logout() {
      this.$store.dispatch("LOGOUT");
      this.toggle();
    },
    account() {
      this.$refs.profileEdit.toggle();
      this.toggle();
    },
    endShift() {
      this.shiftEnd = true;
      this.$refs.endShift.toggle();
      this.toggle();
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.open = false;
        this.arrowCounter = -1;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
<style lang="scss">
.glow-danger {
  animation: glow 1s infinite alternate;
  color: red;
  cursor: pointer;
  @keyframes glow {
    to {
      text-shadow: 0 0 10px red;
      color: white;
    }
  }
}
.n-avatar {
  width: 35px;
  height: 35px;
  background-size: cover;
  border-radius: 17.5px;
}

.data {
  height: 15px;
  margin-top: 20px;
  width: 15px;
  background-color: rgb(0, 233, 0);
  border-radius: 50%;
  display: inline-block;
}
.navbar {
  background: #6e6976;
  height: 70px;
}

.eas-systems {
  display: none;
}

@media (min-width: 1368px) {
  .eas-systems {
    display: flex;
  }
}
// X-Large devices (large desktops, 1200px and up)
.user {
  cursor: pointer;
  padding: 5px 12px;
  &:hover {
    background: #615c69;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  &.closed {
    background: #615c69;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  &:active {
    background: #615c69;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  .profile-actions {
    position: absolute;
    width: 200px;
    z-index: 141;
    right: 11px;
    background: #615c69;
    top: 60px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    ul {
      li {
        .btn {
          color: white;
          width: 100%;
        }
        cursor: pointer;
        &:hover {
          background-color: #e47672;
        }
      }
    }
  }
}
.profile {
  margin-right: 20px;

  .dropdown {
    height: 20px;
    width: 20px;
    position: absolute;
    right: 12px;
    top: 30px;
    cursor: pointer;

    &.open {
      background: url("~@/assets/dropdown_profile_down.png") no-repeat;
    }
    &.closed {
      background: url("~@/assets/dropdown_profile_up.png") no-repeat;
    }
  }
}
</style>
