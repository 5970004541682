<template>
  <div class="invoice" v-if="model" style="min-width: 40vw; height: 80vh">
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <div>
          <p class="label">Vendor</p>
          <div v-if="type !== 'view'">
            <auto-complete
              style="width: 20rem"
              :items="$store.state.ims.vendors.items"
              @select="selectVendor"
              :index="index"
              :selectToInput="true"
              pattern="name"
              placeholder="Vendor"
            />
          </div>
          <input
            type="text"
            class="text"
            v-if="model.vendor && type === 'view'"
            v-model="model.vendor.name"
            placeholder="Vendor"
            :disabled="type === 'view'"
          />
        </div>
        <div class="mx-2" v-if="model.vendor?.id">
          <p class="label">Tax Id</p>
          <input
            type="text"
            disabled
            v-model="model.vendor.taxId"
            class="text"
          />
        </div>
      </div>
      <div class="d-flex">
        <div>
          <p class="label">Invoice Number</p>
          <input
            type="text"
            placeholder="Invoice number"
            :disabled="type === 'view'"
            v-model="model.invoiceNumber"
            class="text"
          />
        </div>
        <div class="mx-2">
          <p class="label">Invoice Date</p>
          <input
            type="date"
            v-if="type !== 'view'"
            placeholder="Invoice Date"
            v-model="model.invoiceDate"
            class="text"
          />

          <div class="text" style="height: 50px" v-else>
            {{ moment(model.invoiceDate).format("DD-MMMM-YYYY") }}
          </div>
        </div>
      </div>
      <div class="d-flex">
        <textarea
          cols="50"
          rows="4"
          class="text-area"
          style="width: 100%"
          :disabled="type === 'view'"
          placeholder="Enter remarks"
          v-model="model.remarks"
        ></textarea>
      </div>
    </div>
    <hr />
    <div class="items">
      <table v-show="model.items.length > 0" class="table">
        <thead>
          <tr>
            <td>Item name</td>
            <td>Qty</td>
            <td>Unit</td>
            <td>Taxable Inclusive?</td>
            <td>Unit Price</td>
            <td>Total</td>
            <td>Packaging</td>
            <td>Receiving</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in model.items" :key="item.id">
            <td style="width: 25vw" class="start">
              <auto-complete
                :items="$store.state.ims.inventories.items"
                v-if="type !== 'view'"
                @select="selectedItem($event, index)"
              />
              <input
                v-else
                type="text"
                v-model="item.name"
                :disabled="type === 'view'"
                class="text"
                placeholder="Item name"
              />
            </td>
            <td>
              <input
                type="number"
                :disabled="type === 'view'"
                class="text"
                v-model="item.qty"
                @change="calculateTotal(index)"
              />
            </td>
            <td>
              <input
                type="text"
                class="text"
                v-if="type === 'view'"
                v-model="item.unit"
              />
              <input
                v-else
                type="text"
                class="text"
                v-model="item.invoiceUnit"
              />
            </td>
            <td>
              <input
                v-model="item.taxInclusive"
                type="checkbox"
                :disabled="type === 'view'"
                placeholder="g"
                class="text"
                @change="calculateTax(index)"
              />
            </td>
            
            <td>
              <input
                type="number"
                class="text"
                :disabled="type === 'view'"
                v-model="item.unitPrice"
                @change="calculateTotal(index)"
              />
            </td>
            <td>
              <input type="number" class="text" v-model="item.total" disabled />
            </td>
            <td>
              <input type="text" class="text" v-model="item.packaging" />
            </td>
            <td>
              <input type="text" class="text" name="" v-model="item.rqty" id=""> <span>{{item.inventoryUnit}}</span>
            </td>
            <td class="end">
              <button class="btn" @click="remove(index)">Remove</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="controls">
      <button class="btn" @click="add">Add Row</button>
    </div>
    <div class="totals">
      <div class="d-flex justify-content-between">
        <div>
          <strong>Subtotal:</strong> <span> {{ subtotal }}</span>
        </div>
        <!-- <div>
          <strong>Discount:</strong>
          <input type="text" v-model="model.discount" class="discount-input" />
        </div> -->
        <div>
          <strong>GST:</strong> <span> {{ tax }}</span>
        </div>
        <div>
          <strong>Total:</strong> <span>MVR {{ total }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AutoComplete from "@/components/AutoComplete.vue";
import moment from "moment";
export default {
  components: { AutoComplete },
  props: {
    form: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "create",
    },
  },
  data() {
    return {
      moment,
      model: this.form,
    };
  },
  computed: {
    subtotal() {
      var total = 0;
      this.model?.items?.forEach((item) => {
        if (!item.isTaxable) {
          total += item.total;
        }
      });
      return total - this.tax;
    },
    tax() {
      var total = 0;
      this.model?.items?.forEach((item) => {
        console.log(item)
        if (item.taxInclusive) {
          total += item.total - item.total / 1.06;
        }
      });
      return total.toFixed(2);
    },
    total() {
      var total = 0;
      this.model?.items?.forEach((item) => {
        total += item.total;
      });
      return total;
    },
    getKeys() {
      var keys = Object.keys(this.model);
      return keys;
    },
    getArray() {
      Object.keys(this.model).forEach((key) => {
        return typeof key === "object";
      });
    },
  },
  methods: {
    add() {
      this.model.items.push({ ...this.model.items[0] });
    },
    remove(index) {
      this.model.items.splice(index, 1);
    },
    selectVendor(vendor) {
      this.model.vendorId = vendor.id;
      this.model.vendor = vendor;
    },
    submit() {
      this.$emit("save", this.form);
    },
    calculateTotal(index) {
      const item = this.model.items[index];

      var qty = item.qty;
      var unitPrice = item.unitPrice;

      if (item.isTaxable) {
        item.subtotal = qty * unitPrice;
        item.gst = qty * unitPrice;
        item.total = qty * unitPrice;
      } else {
        item.total = qty * unitPrice;
      }

      console.log(this.model);
      this.model.subtotal = this.subtotal;
      this.model.tax = this.tax;
      this.model.total = this.total;
    },
    // selected: function (e, index) {
    //   if (!e.name) {
    //     return;
    //   }

    //   var item = this.model?.items[index];
    //   item.inventory = e;
    //   item.unit = e.unit;
    //   item.invoiceUnit = e.invoiceUnit;
    // },
    selectedItem(item, index) {
      console.log(index);
      console.log(item);
      console.log(this.model.items[index]);
      this.model.items[index].unit = item.unit;
      this.model.items[index].unitPrice = 0;
      this.model.items[index].total = 0;
      this.model.items[index].rop = item.rop + " " + item.unit;
      this.model.items[index].onHand = item.qty + " " + item.unit;
      this.model.items[index].rQty = 0;
      this.model.items[index].invoiceUnit = item.invoiceUnit;
      this.model.items[index].inventoryId = item.id;
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>
<style lang="scss" scoped>
.controls {
  position: fixed;
  z-index: 100;
  bottom: 25px;
}

.totals {
  position: fixed;
  z-index: 100;
  bottom: 45px;
  right: 200px;

  div {
    margin-left: 20px;
  }
}
</style>
