<template>
  <div class="customers content-area" style="margin-right: 10px">
    <grid-view
      :endpoint="'crm/customer'"
      :title="'Customers'"
      :headers="[
        'name',
        'phoneNumber',
        'email',
        'type',
        'accountType',
        'joinedDate',
        'isVerified',
        'isActive',
      ]"
      @update-model-changed="updateModelChanged"
      :form="form"
      :body="'form'"
    >
      <template v-slot:row="{ row }">
        <td>{{ row.name }}</td>
        <td>{{ row.phoneNumber ?? "N/A" }}</td>
        <td>{{ row.email }}</td>
        <td>{{ row.type }}</td>
        <td>{{ row.accountType }}</td>
        <td>{{ row.joinedDate }}</td>
        <td>{{ row.isVerified }}</td>
        <td>{{ row.isActive }}</td>
      </template>
      <template v-slot:create>
        <new-customer-modal :customer="form" />
      </template>
      <template v-slot:update="{ updateForm }">
        <edit-customer-form :form="updateForm" />
      </template>
    </grid-view>
  </div>
</template>
<script>
import NewCustomerModal from "./Modals/NewCustomerModal.vue";
import EditCustomerForm from "./Modals/EditCustomerForm.vue";
import GridVue from "@/components/Grid/Grid.vue";
export default {
  components: { EditCustomerForm, NewCustomerModal, "grid-view": GridVue },
  data() {
    return {
      form: {
        id: null,
        phoneNumber: null,
        email: null,
        avatar: null,
        name: null,
        type: null,
        accountType: "Individual",
        isVerified: true,
        department: null,
        jobTitle: null,
        joinedDate: null,
        file: null,
        taxId: null,
      },
    };
  },
  methods: {},
};
</script>
