<template>
  <div class="new-customer">
    <div>
      <label for="huey" style="font-size: 12px; padding-bottom: 5px">
        <input
          type="radio"
          id="huey"
          name="drone"
          value="Individual"
          v-model="customer.accountType"
        />
        Individual</label
      >
      <label
        for="huey"
        class="mx-2"
        style="font-size: 12px; padding-bottom: 5px"
      >
        <input
          type="radio"
          id="huey"
          name="drone"
          value="Business"
          v-model="customer.accountType"
        />
        Business</label
      >
    </div>
    <div class="d-flex">
      <FileUpload
        style="max-height: 120px; width: 200px; margin-right: 10px"
        :image="customer.file"
      />
      <div>
        <div>
          <p class="label">Name</p>
          <input
            type="text"
            autocomplete="false"
            class="text"
            placeholder="Name"
            v-model="customer.name"
          />
        </div>
        <div>
          <p class="label">Phone Number</p>
          <input
            type="number"
            autocomplete="false"
            class="text"
            v-model="customer.phoneNumber"
            placeholder="Phone number"
          />
        </div>
      </div>
    </div>
    <div>
      <p class="label">Email Address</p>
      <input
        type="email"
        autocomplete="false"
        v-model="customer.email"
        class="text"
        placeholder="Email Address"
      />
    </div>
    <div v-show="customer.accountType === 'Business'">
      <div>
        <p class="label">TIN Number</p>
        <input
          type="text"
          autocomplete="false"
          v-model="customer.taxId"
          class="text"
          placeholder="Tax Id"
        />
      </div>
      <div>
        <p class="label">Business Id</p>
        <input
          type="text"
          autocomplete="false"
          v-model="customer.businessId"
          class="text"
          placeholder="Business Registration number"
        />
      </div>
      <div>
        <p class="label">Correspondent</p>
        <input
          type="text"
          autocomplete="false"
          v-model="customer.correspondent"
          class="text"
          placeholder="Correspondent"
        />
      </div>
    </div>

    <p style="font-size: 10px; font-style: italic" class="label">
      Note:By creating a customer in the system you can assign bills to
      customer. Enable credits. View spending by customers. Track customer
      activities. Customers will be identified by their phone numbers. It must
      be unique
    </p>
  </div>
</template>
<script>
import FileUpload from "@/components/FileUpload.vue";

export default {
  components: { FileUpload },
  props: {
    customer: {
      type: Object,
      default: null,
      required: false,
    },
    phone: {
      type: String,
      default: null,
      required: false,
    },
  },
  watch: {},
  data() {
    return {
      form: this.customer,
    };
  },
  mounted() {
    this.customer.phoneNumber = this.phone;
  },
};
</script>
<style lang="scss" scoped>
.new-customer {
  max-width: 350px;
}
</style>
