<template>
  <div class="requests">
    <popup ref="request" :title="'Request'" key="reque">
      <ReceivingForm :form="form" :type="'view'" v-if="form" />
    </popup>
    <grid-view
      :endpoint="'ims/requests'"
      :title="'Inventory Requests'"
      :headers="['id', 'remarks', 'createdBy', 'createdAt', 'status']"
      @update-model-changed="updateModelChanged"
      :form="form"
    >
      <template v-slot:row="{ row }">
        <td>
          <span style="text-decoration: underline" @click="viewRequest(row)">
            {{ row.id }}
          </span>
        </td>
        <td>{{ row.remarks ?? "N/A" }}</td>
        <td>{{ row.createdBy }}</td>
        <td>{{ row.createdAt }}</td>
        <td>{{ row.status }}</td>
      </template>
      <template style="min-width: 50vw !imporant" v-slot:create>
        <item-request-form :model="form" :type="'create'" />
      </template>
      <template v-slot:update="{ updateForm }">
        <item-request-form :model="updateForm" :type="'update'">
        </item-request-form>
      </template>
    </grid-view>
  </div>
</template>
<script>
import GridView from "@/components/Grid/Grid.vue";
import ItemRequestForm from "./Modals/ItemRequestForm.vue";
export default {
  components: { GridView, ItemRequestForm },
  data() {
    return {
      form: {
        id: null,
        createdAt: null,
        createdBy: null,
        modifiedBy: null,
        status: null,
        items: [],
        remarks: null,
      },
    };
  },
  computed: {},
  methods: {
    viewRequest(row) {},
    updateModelChanged(model) {
      this.form = model;
    },
  },
  mounted: function () {},
  created: function () {
    //this.fetch()
  },
};
</script>
<style lang="scss" scoped>
.requests {
  overflow: hidden;
  height: 86vh !important;
  .ps {
    height: 68vh;
  }
}

.tags {
  .tag {
    background: rgb(214, 93, 23);
    border-radius: 5px;
    padding: 2px 8px;
  }
}
</style>
