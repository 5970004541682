<template>
  <router-link class="router-link" :to="{ path: '/' + route }">
    <div class="">
      <div class="icon" :class="title.toLowerCase()"></div>
      <span class="mx-4">{{ title }}</span>
    </div>
  </router-link>
</template>
<script>
export default {
  props: {
    title: String,
    route: {
      type: String,
      required: true,
    },
    icon: String,
  },
};
</script>
<style lang="scss" scoped>
$primary-bg: #6e6976;
.router-link {
  text-decoration: none;
  color: white;
  padding: 5px 10px;
  border-radius: 12px;
  border: 1px solid white;
  margin-left: 10px;

  &:hover,
  &.router-link-active {
    background-color: #fde5e1;
    color: #e47672;
    cursor: pointer;
  }
}

.icon {
}
</style>
