<template>
  <div class="invoice" style="width: 85vw">
    <div v-if="dataModel" class="footer-actions">
      <div class="d-flex justify-content-between">
        <div>
          <button @click="addRow" class="btn">Add row</button>
        </div>
        <div class="d-flex">
          <div class="calculation pt-2 d-flex justify-content-between">
            <div></div>
            <div class="mx-3 d-flex justify-content-between">
              <div class="subtotal">
                Sub Total: <strong>{{ subtotal }}</strong>
              </div>
              <div class="tax mx-3">
                GST: <strong>{{ tax }}</strong>
              </div>
              <div class="total">
                Total: <strong>{{ total }}</strong>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <button class="mx-2 btn secondary">Discard</button>
            <button class="btn primary">Create</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="type !== 'view'">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <div>
            <p class="label">Vendor</p>
            <auto-complete
              style="width: 20rem"
              :items="$store.state.ims.vendors?.items"
              @select="selectVendor"
              :index="index"
              :selectToInput="true"
              pattern="name"
              placeholder="Vendor"
            />
          </div>
          <div class="mx-2" v-if="dataModel.vendor.id">
            <p class="label">Tax Id</p>
            <input
              type="text"
              disabled
              v-model="dataModel.vendor.taxId"
              class="text"
            />
          </div>
        </div>
        <div class="d-flex">
          <div>
            <p class="label">Invoice Number</p>
            <input
              type="text"
              placeholder="Tax Invoice number"
              v-model="dataModel.invoiceNumber"
              class="text"
            />
          </div>
          <div class="mx-2">
            <p class="label">Invoice Date</p>
            <input
              type="date"
              placeholder="Invoice Date"
              v-model="dataModel.invoiceDate"
              class="text"
            />
          </div>
        </div>
      </div>
      <hr />
      <table v-show="model?.items.length > 0" class="table default">
        <thead>
          <tr>
            <td>#</td>
            <td>Item</td>
            <td>Qty</td>
            <td>Unit</td>
            <td>Unit Price</td>
            <td>Gst</td>
            <td>Total</td>
            <td>Taxable</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in model.items" :key="index">
            <td>
              <div
                type="text"
                style="width: 60px"
                class="text"
                placeholder="0"
                disabled
              >
                {{ getIndex(index) }}
              </div>
            </td>
            <td>
              <auto-complete
                style="width: 22rem"
                :items="$store.state.ims.inventories?.items"
                @select="select"
                :index="index"
                :selectToInput="true"
                pattern="name"
                placeholder="Search inventory"
              />
            </td>
            <td>
              <input
                type="text"
                v-model="item.qty"
                class="text"
                placeholder="0"
                @change="calculate(index)"
              />
            </td>
            <td>
              <input
                v-model="item.invoiceUnit"
                type="text"
                class="text"
                disabled
              />
            </td>
            <td>
              <input
                v-model="item.price"
                type="text"
                class="text"
                @change="calculate(index)"
                :placeholder="item.unit ? 'Price/' + item.unit : 'Price'"
              />
            </td>
            <td>
              <input
                v-model="item.tax"
                type="text"
                class="text"
                placeholder="Gst"
              />
            </td>
            <td>
              <input
                v-model="item.total"
                type="text"
                class="text"
                placeholder="Total"
              />
            </td>
            <td>
              <input
                v-model="item.taxInclusive"
                type="checkbox"
                class="text"
                placeholder="g"
                @change="calculate(index)"
              />
            </td>
            <td>
              <button class="btn" @click="remove(index)">-</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <div class="d-flex">
        <div class="col-md-6">
          <div class="">
            <p class="label pt-1">Requested By: {{ dataModel.createdBy }}</p>
            <p class="label pt-1">Requested At: {{ dataModel.createdAt }}</p>
          </div>
        </div>
        <textarea
          cols="30"
          rows="3"
          disabled
          class="text-area"
          placeholder="Enter remarks"
          v-model="dataModel.remarks"
        ></textarea>
      </div>
      <table v-show="model.items.length > 0" class="table table-striped">
        <thead>
          <tr>
            <td>#</td>
            <td>Item name</td>
            <td>On Hand</td>
            <td>Qty</td>
            <td>Unit</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in model.items" :key="item.id">
            <td class="start">
              {{ (index += 1) }}
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.onHand }}
            </td>
            <td>
              {{ item.rQty }}
            </td>
            <td>
              {{ item.unit }}
            </td>
            <td class="end"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    model: Object,
    type: {
      type: String,
      default: "view",
      required: true,
    },
  },
  data() {
    return {
      dataModel: this.model,
      selectedIndex: null,
    };
  },
  computed: {
    subtotal() {
      let total = 0;
      this.dataModel?.items.forEach((item) => {
        var amount = item.price * item.qty;
        if (item.taxInclusive) {
          amount = amount / 1.06;
        }

        total += amount;
      });

      return total?.toFixed(2);
    },
    tax() {
      let total = 0;
      this.dataModel?.items.forEach((item) => {
        var amount = item.price * item.qty;
        if (item.taxInclusive) {
          var taxInclusive = amount - amount / 1.06;
          total += taxInclusive;
        } else {
          total += 0;
        }
      });

      return total?.toFixed(2);
    },
    total() {
      var subtotal = this.subtotal;
      var tax = this.tax;

      return (parseFloat(subtotal) + parseFloat(tax)).toFixed(2);
    },
  },
  methods: {
    getIndex(index) {
      return Number(index) + 1;
    },
    calculate(index) {
      var line = this.dataModel?.items[index];
      line.total = parseFloat(line.qty ?? 0) * parseFloat(line.price ?? 0);
      if (!line.price) {
        line.price = parseFloat(line.total ?? 0) / parseFloat(line.qty ?? 0);
      }
      if (!line.qty) {
        line.qty = parseFloat(line.total ?? 0) / parseFloat(line.price ?? 0);
      }

      if (line.taxInclusive) {
        line.tax = (line.total - line.total / 1.06).toFixed(2);
      } else {
        line.tax = 0;
      }
    },
    addRow() {
      this.dataModel?.items.push({
        inventory: null,
        qty: null,
        unit: null,
        invoiceUnit: null,
        price: null,
        total: null,
        taxInclusive: true,
      });
    },
    remove(index) {
      this.dataModel?.items.splice(index, 1);
    },
    selectVendor(e) {
      this.dataModel.vendor = e;
    },
    select: function (e, index) {
      if (!e.name) {
        return;
      }
      var item = this.dataModel?.items[index];
      item.inventory = e;
      item.unit = e.unit;
      item.invoiceUnit = e.invoiceUnit;
    },
  },
  unmounted: function () {
    this.dataModel = null;
  },
};
</script>
<style lang="scss" scoped>
.invoice {
  .ps {
    height: 320px;
  }

  .footer-actions {
    border-top: 1px solid rgb(197, 197, 197);
    position: fixed;
    padding: 10px;
    padding-right: 20px;
    bottom: 0;
    right: 0;
    width: 100%;
  }

  td {
    padding: 0px 2px !important;
  }
}
</style>
