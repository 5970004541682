<template>
  <div class="search">
    <input
      style="width: 250px"
      @keydown="keydown"
      type="text"
      v-model="value"
      placeholder="Type something here....."
    />
    <button class="btn" @click="search">Search</button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: null,
    };
  },

  methods: {
    search() {
      this.$emit("search", this.value);
      this.value = "";
    },
    keydown(e) {
      if (e.keyCode === 13) {
        this.search();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.search {
  height: 60px !important;
  background: white;
  display: flex;
  button {
    border: 1px solid #b7b7b7;
    border-top-right-radius: 12px;
    border-right: none;
    border-bottom-right-radius: 12px;
    background: #f3f3f3;
    height: 40px;
    &:focus {
      outline: none;
    }
  }
  input {
    height: 40px;
    padding: 10px;
    border-top: 1px solid #b7b7b7;
    border-left: 1px solid #b7b7b7;
    background: #f3f3f3;
    border-bottom: 1px solid #b7b7b7;
    border-top-left-radius: 12px;
    border-right: none;
    border-bottom-left-radius: 12px;
    &:focus {
      outline: none;
    }
  }
}
</style>
