<template>
  <div class="open-shift">
    <div class="row" style="margin: 0">
      <div class="col-md">
        <div class="cash-counter">
          <table class="table cash-counter-table table-borderless">
            <tbody>
              <tr v-for="note in notes" :key="note">
                <td>
                  <p>{{ note.label }}</p>
                </td>
                <td>
                  <input
                    class="text"
                    type="text"
                    v-model="note.qty"
                    placeholder="500"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <hr />

    <div class="d-flex justify-content-between">
      <div style="font-size: 20px">MVR {{ total }}</div>
      <!-- <div class="d-flex w-25">
        <button @click="openShift" :disabled="loading" class="btn primary mt-4">
          <span v-if="!loading">Create shift</span>
          <span
            v-else
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      shift: null,
      info: null,
      notes: {
        "1000 x": { label: "1000", amount: 1000, qty: 0 },
        "500 x": { label: "500", amount: 500, qty: 0 },
        "100 x": { label: "100", amount: 100, qty: 0 },
        "50 x": { label: "50", amount: 50, qty: 0 },
        "20 x": { label: "20", amount: 20, qty: 0 },
        "10 x": { label: "10", amount: 10, qty: 0 },
        "5 x": { label: "5", amount: 5, qty: 0 },
        Coins: { label: "Coins", amount: 1, qty: 0 },
      },
    };
  },
  computed: {
    total() {
      let total = 0;
      Object.keys(this.notes).forEach((item) => {
        total += this.notes[item].amount * this.notes[item].qty;
      });

      return total;
    },
  },
  methods: {
    async openShift() {
      const payload = {
        cashDrawerBalance: this.total,
        payload: JSON.stringify(this.notes),
      };

      this.loading = true;
      await this.$api.openShift(payload).then(
        (response) => {
          this.$store.commit("OPEN_SHIFT", response.data);
          this.loading = false;
          this.$emit("shift-created");
        },
        (error) => {
          this.$notify({
            group: "error",
            title: "Shift opening failed",
            text: error.message,
            type: "error",
            duration: 2000,
            speed: 1000,
          });
          this.loading = false;
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.open-shift {
  position: relative;
}
</style>
