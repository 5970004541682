import { createStore } from "vuex";
import jwt_decode from "jwt-decode";
import units from "./units.json";
import api from "../services/restApiService";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
// eslint-disable-next-line no-unused-vars
var ls = new SecureLS({ isCompression: false });

export default createStore({
  state: {
    init: {
      slot: false,
    },
    isSlotContainerOpen: false,
    tableTransferToggle: false,
    selectedSlotType: "Table",
    toggleNewShift: false,
    units: units,
    restCall: false,
    notifications: [],
    search: null,
    loading: {
      bis: false,
      ims: false,
      dms: false,
      crm: false,
    },
    user: null,
    outlet: null,
    bis: {
      current: null,
      bills: [],
    },
    ims: {
      vendors: [],
    },
    variables: {
      slots: {
        Table: {
          count: 50,
          gst: 6,
          serviceCharge: 10,
          checking: {
            pax: {
              type: "single",
              options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
            },
          },
        },
        Delivery: {
          count: 100,
          gst: 6,
          serviceCharge: 0,
          checking: {
            pax: {
              type: "single",
              options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
            },
          },
        },
        Takeaway: {
          count: 100,
          gst: 6,
          serviceCharge: 0,
          checking: {
            pax: {
              type: "single",
              options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
            },
          },
        },
      },
      Size: {
        default: "Medium",
      },
    },

    inventory: [],
    menu: {
      selectedMenu: null,
      selectedCategory: null,
      selectedMenuItem: null,
      data: null,
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.user;
    },
    getShift(state) {
      return state.bis.shift;
    },
    getUser(state) {
      return state.user;
    },
    getOutlet(state) {
      return state.outlet;
    },
    getCurrentSlot(state) {
      return state.bis.current;
    },
    getSelectedSlotType(state) {
      return state.selectedSlotType;
    },
    getSlot(state) {
      if (state.bis.bills.length === 0) {
        return;
      }

      var _slot = state.bis.bills.find((bill) => {
        return (
          bill.slot === state.bis.current?.id &&
          bill.type === state.bis.current?.type
        );
      });

      return _slot;
    },
    menus: (state) => {
      return state.bis.menus;
    },
    getInventory: (state) => {
      return state.inventory;
    },
    getBill: (state) => {
      return state.bis.bills.find(
        (bill) => bill.slot === state.bis.current.slot
      );
    },
  },
  mutations: {
    NOTIFICATION_RECEIVED(state, payload) {
      state.notifications.unshift({
        ...payload,
        createdAt: new Date(),
        readAt: null,
        read: false,
      });
    },
    ADD_MENU(state, payload) {
      state.bis.menus.push(payload);
    },
    INIT_IMS(state, payload) {
      state.ims = payload;
      console.log(state.ims);
      state.loading.ims = false;
    },
    INIT_CRM(state, payload) {
      state.crm = payload;
      state.loading.crm = false;
    },
    END_SHIFT(state, payload) {
      state.user = null;
      state.bis.shift = payload;
    },
    OPEN_SHIFT(state, payload) {
      state.bis.shift = payload;
    },
    INIT_BIS(state, payload) {
      state.bis.history = payload.history;
      state.bis.menus = payload.menus;

      state.bis.shift = payload.shift;

      if (payload.shift === null) {
        this.toggleNewShift = true;
      }
      payload.bills.forEach((element) => {
        var slot = state.bis.bills.findIndex(
          (x) => x.type === element.type && x.slot === element.slot
        );
        state.bis.bills[slot] = element;
      });
      state.loading.bis = false;
    },
    REST_CALL(state, payload) {
      state.restCall = payload;
    },
    NOTIFY(state, payload) {
      state.notifications.unshift(payload);
    },
    CHECKOUT(state, payload) {
      var _billFromHistory = state.bis.history?.items.findIndex((x) => {
        return x.id === payload.id;
      });

      if (_billFromHistory > -1) {
        state.bis.history.items[_billFromHistory] = payload;
      } else {
        state.bis.history.items.unshift(payload);
      }

      var reset = {
        id: null,
        slot: payload.slot,
        type: payload.type,
        address: null,
        createdBy: null,
        createdAt: null,
        status: null,
        pax: null,
        meta: null,
        customer: null,
        items: [],
        shiftId: null,
        totals: {
          subtotal: 0,
          discount: 0,
          serviceCharge: 0,
          gst: 0,
          total: 0,
        },
      };
      var _bill = state.bis.bills.findIndex((x) => {
        return x.slot === payload.slot && x.type === payload.type;
      });

      state.bis.bills[_bill] = reset;
      if (
        state.bis.current.slot === reset.slot &&
        state.bis.current.type === reset.type
      ) {
        state.bis.current.status = "";
        state.bis.current = reset;
      }
    },
    UPDATE_MENU_ITEM(state, payload) {
      var menu = state.bis.menus.find((x) => x.id === payload.menuId);
      var category = menu.categories.find((x) => x.id === payload.categoryId);
      var itemIndex = category.menuItems.findIndex((x) => x.id === payload.id);

      category.menuItems[itemIndex] = payload;
    },
    UPDATE_DELIVERY_STATUS(state, payload) {
      var _bill = state.bis.bills.findIndex(
        (x) => x.slot === payload.slot && x.type === payload.type
      );

      state.bis.bills[_bill].deliveryStatus = payload.deliveryStatus;
    },
    UPDATE_BILL(state, payload) {
      if (state.bis.current?.status === "BillPrinted") {
        if (payload.status === "OrderTaken") {
          payload.status = "OrderServed";
        }
      }

      var _bill = state.bis.bills.findIndex((x) => {
        return x.slot === payload.slot && x.type === payload.type;
      });

      var _billFromHistory = state.bis.history?.items.findIndex((x) => {
        return x.id === payload.id;
      });

      if (_billFromHistory > -1) {
        state.bis.history.items[_billFromHistory] = payload;
      } else {
        state.bis.history.items.unshift(payload);
      }
      state.bis.bills[_bill] = payload;

      if (
        state.bis.current?.slot === payload.slot &&
        state.bis.current?.type === payload.type
      ) {
        state.bis.current = payload;
      }
    },

    VOID_BILL(state, payload) {
      var _billFromHistory = state.bis.history?.items.findIndex((x) => {
        return x.slot === payload.slot && x.type === payload.type;
      });

      var bills = state.bis.bills.findIndex((x) => {
        return x.slot === payload.slot && x.type === payload.type;
      });

      if (bills > -1) {
        state.bis.bills.splice(bills, 1);
      }

      state.bis.history.items[_billFromHistory] = payload;
    },
    ADD_BILL_ITEM(state, payload) {
      if (this.state.bis.current.status === null) {
        return;
      }
      payload.createdBy = state.user.data.dn;
      payload.notes = [];
      state.bis.current.items?.unshift(payload);
    },
    ADD_NEW_ITEM(state, payload) {
      var menu = state.bis.menus.find((menu) => menu.id === payload.menuId);
      var cat = menu.categories.find((c) => c.id === payload.categoryId);
      cat.menuItems.push(payload);
    },
    ADD_CATEGORY(state, payload) {
      var menu = state.bis.menus.find((x) => x.id === payload.menuId);
      menu.categories.push(payload);
    },
    SET_SELECTED_SLOT_TYPE(state, payload) {
      state.selectedSlotType = payload;
    },
    SET_ACTIVE_SLOT(state, payload) {
      if (state.bis.current === null) {
        state.bis.current = payload;
        return;
      }
      var _bill = state.bis.bills.findIndex((x) => {
        return (
          x.slot === state.bis.current.slot && x.type === state.bis.current.type
        );
      });

      state.bis.bills[_bill] = state.bis.current;
      state.bis.current = payload;
    },
    SET_CUSTOMER(state, payload) {
      var _bill = state.bis.bills.findIndex((x) => {
        return (
          x.slot === state.bis.current.slot && x.type === state.bis.current.type
        );
      });

      state.bis.bills[_bill].customer = payload;
      state.bis.current.customer = payload;
    },
    CHECKING(state, payload) {
      state.bis.current.status = payload.status;
      state.bis.current.meta = payload.meta;
      state.bis.current.createdAt = new Date();

      var newBill = state.bis.current;

      var _bill = state.bis.bills.findIndex((x) => {
        return (
          x.slot === state.bis.current.slot && x.type === state.bis.current.type
        );
      });

      state.bis.bills[_bill] = newBill;
    },
    LOGIN_SUCCESS(state, payload) {
      state.user = payload;
    },
    LOGOUT(state) {
      state.user = null;
    },
    async INIT_SLOTS(state) {
      // if (state.init?.slots) {
      //   return
      // }
      state.bis.current = null;
      state.bis.bills = [];
      var keys = Object.keys(state.variables.slots);
      keys.forEach((type) => {
        const slots = state.variables.slots[type].count;
        for (let index = 1; index <= slots; index++) {
          var slot = {
            slot: index.toString(),
            type: type,
            createdBy: null,
            createdAt: null,
            status: null,
            address: null,
            pax: null,
            meta: null,
            shiftId: null,
            customer: null,
            items: [],
            totals: {
              subtotal: 0,
              discount: 0,
              serviceCharge: 0,
              gst: 0,
              total: 0,
            },
          };
          state.bis.bills.push(slot);
        }
      });

      state.init.slots = true;
    },
    async LOAD_BILLS(state) {
      state.bis.bills.forEach((item) => {
        var index = Number(item.slot);
        state.bis.bills[index - 1] = item;
      });
    },

    async LOAD_MENU(state) {
      state.loading.menu = true;
      var menu = await api.fetchMenu();
      state.menu.data = menu.data;
      state.menu.selectedMenu = menu.data[0];
      state.menu.selectedCategory = menu.data[0]?.categories[0];
      state.loading.menu = false;
    },
    async LOAD_INVENTORY(state) {
      state.loading.inventory = true;
      var response = await api.fetchInventory(1, 5000);
      state.ims.inventories = response.data;
      state.loading.inventory = false;
    },
    SET_OUTLET(state, payload) {
      state.outlet = payload;
    },

    //VENDOR MUTATIONS
    ADD_VENDOR(state, payload) {
      state.ims.vendors.unshift(payload);
    },
    DELETE_VENDOR(state, payload) {
      state.ims.vendors.splice(state.ims.vendors.indexOf(payload));
    },
    UPDATE_VENDOR(state, payload) {
      var vendor = state.ims.vendors.find((x) => x.id === payload);
      vendor == payload;
    },
  },
  actions: {
    NOTIFICATION_RECEIVED({ commit }, payload) {
      commit("NOTIFICATION_RECEIVED", payload);
    },
    TOGGLE_TABLE_TRANSFER({ commit }) {
      commit("TOGGLE_TABLE_TRANSFER");
    },
    VOID_BILL({ commit }, payload) {
      commit("VOID_BILL", payload);
    },
    UPDATE_MENU_ITEM({ commit }, payload) {
      commit("UPDATE_MENU_ITEM", payload);
    },
    ADD_VENDOR({ commit }, payload) {
      commit("ADD_VENDOR", payload);
    },
    DELETE_VENDOR({ commit }, payload) {
      commit("DELETE_VENDOR", payload);
    },
    UPDATE_VENDOR({ commit }, payload) {
      commit("UPDATE_VENDOR", payload);
    },
    CHECKOUT({ commit }, payload) {
      commit("CHECKOUT", payload);
    },

    UPDATE_BILL({ commit }, payload) {
      commit("UPDATE_BILL", payload);
    },
    SET_CUSTOMER({ commit }, payload) {
      commit("SET_CUSTOMER", payload);
    },
    REST_CALL({ commit }, payload) {
      commit("REST_CALL", payload);
    },
    NOTIFY({ commit }, payload) {
      commit("NOTIFY", payload);
    },
    ADD_NEW_ITEM({ commit }, payload) {
      commit("ADD_NEW_ITEM", payload);
    },
    INIT_SLOTS({ commit }) {
      commit("INIT_SLOTS");
    },
    LOAD_DATA({ commit, state }) {
      state.loading.bis = true;
      return new Promise((resolve, reject) => {
        if (state.user.data.bis) {
          commit("INIT_SLOTS");
          api.init("bis").then(
            (response) => {
              commit("INIT_BIS", response.data);
              resolve(response);
            },
            (error) => {
              reject(error);
              state.loading.bis = false;
            }
          );
        }

        if (state.user.data.ims) {
          state.loading.ims = true;
          api.init("ims").then(
            (response) => {
              commit("INIT_IMS", response.data);
              resolve(response);
            },
            (error) => {
              state.loading.ims = false;
              reject(error);
            }
          );
        }

        if (state.user.data.crm) {
          state.loading.crm = true;
          api.init("crm").then(
            (response) => {
              commit("INIT_CRM", response.data);
              resolve(response);
            },
            (error) => {
              state.loading.crm = false;
              reject(error);
            }
          );
        }
      });
    },
    LOGOUT({ commit }) {
      commit("LOGOUT");
    },
    LOGIN_SUCCESS({ commit }, payload) {
      var decoded = jwt_decode(payload.token);
      var session = {
        refreshToken: payload.refreshToken,
        token: payload.token,
        data: decoded,
        avatar: payload.avatar,
        employee: payload.employee,
      };

      commit("SET_OUTLET", {
        id: decoded.outletId,
      });
      commit("LOGIN_SUCCESS", session);
    },
    CHECKING({ commit }, payload) {
      commit("CHECKING", payload);
    },
    ADD_BILL_ITEM({ commit }, payload) {
      commit("ADD_BILL_ITEM", payload);
    },
    SET_ACTIVE_SLOT({ commit }, payload) {
      commit("SET_ACTIVE_SLOT", payload);
    },
    SET_SELECTED_SLOT_TYPE({ commit }, payload) {
      commit("SET_SELECTED_SLOT_TYPE", payload);
    },
    ADD_MENU({ commit }, payload) {
      commit("ADD_MENU", payload);
    },
    ADD_CATEGORY({ commit }, payload) {
      commit("ADD_CATEGORY", payload);
    },
    SET_OUTLET({ commit }, payload) {
      commit("SET_OUTLET", payload);
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      key: "Urbist Store",
      // storage: {
      //   getItem: (key) => ls.get(key),
      //   setItem: (key, value) => ls.set(key, value),
      //   removeItem: (key) => ls.remove(key),
      // },
    }),
  ],
});
